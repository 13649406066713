/* eslint-disable react/display-name */
import React, {useState, useRef} from 'react'
import gql from 'graphql-tag'
import { Query, compose, withApollo, Mutation } from 'react-apollo'
import { withTranslation } from 'react-i18next'
import CustomLoader from '../components/Loader/CustomLoader'
// reactstrap components
import {Card, Container, FormGroup, Input, Row, Button} from 'reactstrap'
// core components
import Header from '../components/Headers/Header'
import { restaurants, toggleFeatured, toggleAvailabilityAdmin, deleteRestaurant, getDistinctCities } from '../apollo/server'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { handleErrors } from '../utils/handleErrors'
import { usePlacesWidget } from "react-google-autocomplete"
import Select from 'react-select';

const GET_RESTAURANTS = gql`
  ${restaurants}
`

const GET_DISTINCT_CITIES = gql`
  ${getDistinctCities}
`

const TOGGLE_FEATURED = gql`
  ${toggleFeatured}
`

const TOGGLE_AVAILABILITY = gql`
  ${toggleAvailabilityAdmin}
`

const Restaurants = props => {

  const searchRef = useRef(null)
  const [filter, setFilter] = useState('')
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [cityOption, setcityOption] = useState(null);
  const [city, setcity] = useState(null);

  const { ref: bootstrapRef } = usePlacesWidget({
    onPlaceSelected: (place) => {
      const location = place.geometry.location;
      const latitude = location.lat()
      const longitude = location.lng()
      setLongitude(longitude)
      setLatitude(latitude)
    },
  });

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const columns = [
    {
      name: 'Image',
      cell: row => (
        <>
          {!!row.image && (
            <img
              className="img-responsive"
              src={row.image}
              alt="img menu"
              onClick={() => {
                localStorage.setItem('restaurant_id', row._id)
                props.history.push('/admin/dashboard')
              }}
            />
          )}
          {!row.image && 'No Image'}
        </>
      ),
      selector: 'image'
    },
    {
      name: 'Name',
      sortable: true,
      selector: 'name'
    },
    {
      name: 'Address',
      selector: 'address'
    },
    {
      name: 'Order Prefix',
      selector: 'orderPrefix'
    },
    {
      name: 'Featured',
      cell: row => <>{toggleFeatured(row)}</>
    },
    {
      name: 'Approved',
      cell: row => <>{availableApproved(row)}</>,
      reorder: true
    },
    {
      name: 'Available',
      cell: row => <>{toggleAvailabilityAdmin(row)}</>,
      reorder: true
    },
  ]

  const availableApproved = row => {
    return (
        <>
          <label className="custom-toggle">
            <Mutation  mutation={gql`
          ${deleteRestaurant}
          `}>
              {deleteRestaurant => {
                return (
                    <input
                        checked={!!row.isActive}
                        type="checkbox"
                        onChange={event => {
                          deleteRestaurant({ variables: { id: row._id } })
                        }}
                    />
                )
              }}
            </Mutation>
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </>
    )
  }

  const toggleAvailabilityAdmin = row => {
    return (
        <>
          <label className="custom-toggle">
            <Mutation  mutation={TOGGLE_AVAILABILITY}>
              {toggleAvailabilityAdmin => {
                return (
                    <input
                        checked={!!row.isAvailable}
                        type="checkbox"
                        onChange={event => {
                          toggleAvailabilityAdmin({ variables: { restaurantId: row._id } })
                        }}
                    />
                )
              }}
            </Mutation>
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </>
    )
  }

  const toggleFeatured = row => {
    return (
      <>
        <label className="custom-toggle">
          <Mutation mutation={TOGGLE_FEATURED}>
            {toggleFeatured => {
              return (
                <input
                  checked={!!row.featured}
                  type="checkbox"
                  onChange={event => {
                    toggleFeatured({ variables: { restaurantId: row._id } })
                  }}
                />
              )
            }}
          </Mutation>
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </>
    )
  }
  const conditionalRowStyles = [
    {
      when: row => !row.isActive,
      style: {
        backgroundColor: 'rgba(240, 173, 78,0.2)'
      }
    }
  ]
  const { t } = props

  return (
    <>
      <Header />
      {/* Page content */}


      <Container className="mt--7" fluid>
        {/* Table */}


        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              {
              <Query
                  fetchPolicy={'network-only'}
                  query={GET_RESTAURANTS}
                  variables={{
                    filter,
                    latitude,
                    longitude,
                    city
                  }}
              >
                {({ loading, error, data }) => {
                  if (error) {
                    const errorMsg = handleErrors(error.graphQLErrors)
                    return (
                      <span>
                        {t('Error')}! {errorMsg}
                      </span>
                    )
                  }
                  return (
                      <>
                        <FormGroup className=''>
                          <div className="col">
                          <label
                              className="form-control-label"
                              htmlFor="input-type-username">
                            Filter Restaurants
                          </label>
                          <Input
                              innerRef={searchRef}
                              className="form-control-alternative"
                              id="input-description"
                              placeholder="Search By Restaurant Name"
                              type="text"
                          />
                            OR <br/>
                            <Input
                                innerRef={bootstrapRef}
                                className="form-control-alternative"
                                id="input-description"
                                placeholder="Search By Location Radius"
                                type="text"
                            />
                             OR <br/>
                             <Query
                                fetchPolicy={'network-only'}
                                query={GET_DISTINCT_CITIES}
                            >
                            {({ loading, error, data }) => {
                              const citiesArray = []
                              if(data && data.getDistinctCities){
                              const cities = data.getDistinctCities
                              for (const city of cities) {
                                citiesArray.push({
                                  value: city,
                                  label: city
                                })
                              }
                            }
                              if (error) {
                                const errorMsg = handleErrors(error.graphQLErrors)
                                return (
                                  <span>
                                    {t('Error')}! {errorMsg}
                                  </span>
                                )
                              }
                              return (
                                        <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        name="color"
                                        onChange={setcityOption}
                                        placeholder="Select City"
                                        options={citiesArray}
                                        />
                              )}}
                               </Query>
                            <Button
                                className={"mt-3"}
                                disabled={loading}
                                onClick={() =>{
                                  setFilter(searchRef.current.value)
                                  setcity(cityOption ? cityOption.value : null)
                                }}
                                color="primary"
                                >Search</Button>
                          </div>
                        </FormGroup>
                        <DataTable
                            title={`Restaurants ${filter ? `(filter: ${filter})` : ''}`}
                            columns={columns}
                            data={data.restaurants}
                            pagination
                            progressPending={loading}
                            progressComponent={<CustomLoader />}
                            onSort={handleSort}
                            sortFunction={customSort}
                            defaultSortField="name"
                            onRowClicked={row => {
                              localStorage.setItem('restaurant_id', row._id)
                              props.history.push('/admin/dashboard')
                            }}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                      </>

                  )
                }}
              </Query>
             }
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default compose(withApollo, withTranslation())(Restaurants)
