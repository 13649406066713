import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  UncontrolledAlert
} from 'reactstrap'

import { Redirect } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { ownerLogin } from '../apollo/server'
import { validateFunc } from '../constraints/constraints'
const LOGIN = gql`
  ${ownerLogin}
`

const Login = props => {
  const [stateData, setStateData] = useState({
    email: '',
    password: '',
    emailError: null,
    passwordError: null,
    error: null,
    type: null, /// 0 for vendor
    redirectToReferrer: !!localStorage.getItem('user-snap')
  })

  const onBlur = (event, field) => {
    setStateData({
      ...stateData,
      [field + 'Error']: !validateFunc({ [field]: stateData[field] }, field)
    })
  }
  const validate = () => {
    const emailError = !validateFunc({ email: stateData.email }, 'email')
    const passwordError = !validateFunc(
      { password: stateData.password },
      'password'
    )
    setStateData({ ...stateData, emailError, passwordError })
    return emailError && passwordError
  }
  const { redirectToReferrer, type } = stateData
  const { t } = props
  if (redirectToReferrer && type === 0) {
    return <Redirect to="/restaurant/list" />
  }
  if (redirectToReferrer && type === 1) {
    return <Redirect to="/super_admin/dispatch" />
  }
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>{t('Sign in credentials')}</small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup
                className={
                  stateData.emailError === null
                    ? ''
                    : stateData.emailError
                    ? 'has-success'
                    : 'has-danger'
                }>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={stateData.email}
                    onChange={event => {
                      setStateData({ ...stateData, email: event.target.value })
                    }}
                    onBlur={event => {
                      onBlur(event, 'email')
                    }}
                    placeholder="Email"
                    type="email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup
                className={
                  stateData.passwordError === null
                    ? ''
                    : stateData.passwordError
                    ? 'has-success'
                    : 'has-danger'
                }>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={stateData.password}
                    onChange={event => {
                      setStateData({
                        ...stateData,
                        password: event.target.value
                      })
                    }}
                    onBlur={event => {
                      onBlur(event, 'password')
                    }}
                    placeholder="Password"
                    type="password"
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Mutation
                  mutation={LOGIN}
                  onCompleted={data => {
                    localStorage.setItem(
                      'user-snap',
                      JSON.stringify(data.ownerLogin)
                    )
                    const userType = data.ownerLogin.userType
                    if (userType === 'VENDOR') {
                      setStateData({
                        ...stateData,
                        redirectToReferrer: true,
                        type: 0,
                        emailError: null,
                        passwordError: null
                      })
                    } else {
                      setStateData({
                        ...stateData,
                        redirectToReferrer: true,
                        type: 1,
                        emailError: null,
                        passwordError: null
                      })
                    }
                  }}
                  onError={error => {
                    console.log(JSON.stringify(error))
                    if (error.graphQLErrors.length) {
                      setStateData({
                        error: error.graphQLErrors[0].message
                      })
                    }
                    if (error.networkError) {
                      setStateData({
                        error: error.networkError.result.errors[0].message
                      })
                    }
                  }}>
                  {(ownerLogin, { loading, error }) => {
                    return (
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={() => {
                          setStateData({
                            ...stateData,
                            emailError: null,
                            passwordError: null
                          })
                          if (validate()) {
                            ownerLogin({ variables: { ...stateData } })
                          }
                        }}>
                        {t('Sign in')}
                      </Button>
                    )
                  }}
                </Mutation>
              </div>
              {stateData.error && (
                <UncontrolledAlert color="danger" fade={true}>
                  <span className="alert-inner--text">{stateData.error}</span>
                </UncontrolledAlert>
              )}
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default withTranslation()(Login)
