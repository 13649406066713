/* eslint-disable react/display-name */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo'
import { withTranslation } from 'react-i18next'
import SectionComponent from '../components/Section/Section'
import CustomLoader from '../components/Loader/CustomLoader'

// reactstrap components
import { Badge, Card, Container, Row, Modal } from 'reactstrap'

// core components
import { deleteSection, editSection, getSections } from '../apollo/server'
import Header from '../components/Headers/Header'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import Loader from 'react-loader-spinner'
import { handleErrors } from '../utils/handleErrors'

const GET_SECTIONS = gql`
  ${getSections}
`
const EDIT_SECTION = gql`
  ${editSection}
`

const DELETE_SECTION = gql`
  ${deleteSection}
`

function Sections(props) {
  const [editModal, setEditModal] = useState(false)
  const [sections, setSections] = useState(null)
  const toggleModal = section => {
    setEditModal(!editModal)
    setSections(section)
  }

  const restaurantId = localStorage.getItem('restaurant_id')

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const onCompleted = ({ deleteSection }) => {
    console.log(deleteSection)
  }
  const onError = error => {
    console.log(error)
  }

  const columns = [
    {
      name: 'Name',
      sortable: true,
      selector: 'name'
    },
    {
      name: 'Status',
      sortable: false,
      cell: row => <>{statusChanged(row)}</>
    },
    {
      name: 'Restaurants',
      sortable: true,
      cell: row => <>{row.restaurants.map(item => `${item.name}`).join(', ')}</>
    },
    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>
    }
  ]

  const statusChanged = row => {
    return (
      <Mutation
        mutation={EDIT_SECTION}
        onError={error =>
          alert(
            `Something is not working. Please contact support team. ${error}`
          )
        }>
        {(mutate, { loading, error }) => (
          <label className="custom-toggle">
            <input
              onClick={() => {
                mutate({
                  variables: {
                    section: {
                      _id: row._id,
                      name: row.name,
                      enabled: !row.enabled,
                      restaurants: row.restaurants
                        ? row.restaurants.map(r => r._id)
                        : []
                    }
                  }
                })
              }}
              defaultChecked={row.enabled}
              type="checkbox"
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        )}
      </Mutation>
    )
  }

  const actionButtons = row => {
    return (
      <>
        <Badge
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          Edit
        </Badge>
        &nbsp;&nbsp;
        <Mutation
          mutation={DELETE_SECTION}
          refetchQueries={[{ query: GET_SECTIONS }]}
          onCompleted={onCompleted}
          onError={onError}>
          {(deleteSection, { loading: sectionLoading }) => {
            if (sectionLoading) {
              return (
                <Loader
                  type="ThreeDots"
                  color="#BB2124"
                  height={20}
                  width={40}
                  visible={sectionLoading}
                />
              )
            }
            return (
              <Badge
                href="#pablo"
                color="danger"
                onClick={e => {
                  e.preventDefault()
                  deleteSection({ variables: { id: row._id } })
                }}>
                {'Delete'}
              </Badge>
            )
          }}
        </Mutation>
      </>
    )
  }

  const { t } = props
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <SectionComponent />
        {/* Table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <Query query={GET_SECTIONS} variables={{ id: restaurantId }}>
                {({ loading, error, data }) => {
                  if (error) {
                    const errorMsg = handleErrors(error.graphQLErrors)
                    return (
                      <tr>
                        <td>
                          {t('Error')}! {errorMsg}
                        </td>
                      </tr>
                    )
                  }
                  return (
                    <DataTable
                      title={'Restaurant Sections'}
                      columns={columns}
                      data={data.sections}
                      pagination
                      progressPending={loading}
                      progressComponent={<CustomLoader />}
                      onSort={handleSort}
                      sortFunction={customSort}
                      defaultSortField="name"
                    />
                  )
                }}
              </Query>
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal(null)
          }}>
          <SectionComponent section={sections} />
        </Modal>
      </Container>
    </>
  )
}

export default withTranslation()(Sections)
