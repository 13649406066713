/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
// reactstrap components
import { Badge, Card, Container, Row, Modal } from 'reactstrap'
import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import Header from '../components/Headers/Header'
import VendorComponent from '../components/Vendor/Vendor'
import CustomLoader from '../components/Loader/CustomLoader'
import { getVendors, deleteVendor } from '../apollo/server'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import Loader from 'react-loader-spinner'
import { handleErrors } from '../utils/handleErrors'

const GET_VENDORS = gql`
  ${getVendors}
`
const DELETE_RIDER = gql`
  ${deleteVendor}
`

const Vendors = props => {
  const [editModal, setEditModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const onCompleted = data => {}
  const onError = error => {
    console.log(error)
  }

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const columns = [
    {
      name: 'Email',
      sortable: true,
      selector: 'email'
    },
    {
      name: 'Total Restaurants',
      sortable: true,
      cell: row => <>{row.restaurants.length}</>
    },
    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>
    }
  ]
  const actionButtons = row => {
    return (
      <>
        <Badge
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            props.history.push('/restaurant/list', { id: row._id })
          }}
          color="info">
          Restaurants
        </Badge>
        &nbsp;&nbsp;
        <Badge
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          Edit
        </Badge>
        &nbsp;&nbsp;
        <Mutation
          mutation={DELETE_RIDER}
          onCompleted={onCompleted}
          onError={onError}
          refetchQueries={[{ query: GET_VENDORS }]}>
          {(deleteVendor, { loading: vendorLoading }) => {
            console.log('deleteVendorFn:', deleteVendor)
            if (vendorLoading) {
              return (
                <Loader
                  type="ThreeDots"
                  color="#BB2124"
                  height={20}
                  width={40}
                  visible={vendorLoading}
                />
              )
            }
            return (
              <Badge
                href="#pablo"
                color="danger"
                onClick={e => {
                  e.preventDefault()
                  deleteVendor({ variables: { id: row._id } })
                }}>
                {'Delete'}
              </Badge>
            )
          }}
        </Mutation>
      </>
    )
  }
  const { t } = props
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <VendorComponent />
        {/* Table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <Query
                query={GET_VENDORS}
                onCompleted={data => console.log(data)}
                onError={error => {
                  console.log(error)
                }}>
                {({ loading, error, data }) => {
                  if (error) {
                    const errorMsg = handleErrors(error.graphQLErrors)
                    return (
                      <span>
                        {t('Error')}! {errorMsg}
                      </span>
                    )
                  }
                  return (
                    <DataTable
                      title={'Vendors'}
                      columns={columns}
                      data={data.vendors}
                      pagination
                      progressPending={loading}
                      progressComponent={<CustomLoader />}
                      onSort={handleSort}
                      sortFunction={customSort}
                      defaultSortField="email"
                    />
                  )
                }}
              </Query>
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal()
          }}>
          <VendorComponent vendor={vendors} />
        </Modal>
      </Container>
    </>
  )
}

export default withTranslation()(Vendors)
