import React, { useRef, useState } from 'react'
import { Mutation } from 'react-apollo'
import { withTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap'

import { CREATE_DIET, GET_DIETS } from '../../apollo/dietary'

const DietaryForm = ({ t }) => {
  const formRef = useRef()
  const [mainError, mainErrorSetter] = useState('')
  const [success, successSetter] = useState('')

  const clearFields = () => {
    formRef.current.reset()
  }

  const onCompleted = data => {
    successSetter('Diet added successfully')
    clearFields()
    mainErrorSetter('')
  }

  const onError = error => {
    const message = `Action failed. Please Try again ${error}`
    successSetter('')
    mainErrorSetter(message)
  }

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">{t('Add Diet')}</h3>
              </Col>
            </Row>
          </CardHeader>

          <CardBody>
            <form ref={formRef}>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="name">
                      {t('Name')}
                    </label>

                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        id="name"
                        name="name"
                        placeholder="e.g Breakfast"
                        type="text"
                        defaultValue={''}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Mutation
                    mutation={CREATE_DIET}
                    onCompleted={onCompleted}
                    onError={onError}
                    refetchQueries={[{ query: GET_DIETS }]}>
                    {(createDietary, { loading, error }) => {
                      if (loading) return t('Loading')
                      return (
                        <Col className="text-right" xs="12">
                          <Button
                            disabled={loading}
                            color="primary"
                            onClick={async e => {
                              e.preventDefault()
                              if (!loading) {
                                createDietary({
                                  variables: {
                                    name: formRef.current['name'].value
                                  }
                                })
                              }
                            }}
                            size="md">
                            {t('Save')}
                          </Button>
                        </Col>
                      )
                    }}
                  </Mutation>
                </Row>

                <Row>
                  <Col lg="6">
                    {success && (
                      <UncontrolledAlert color="success" fade={true}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{' '}
                        <span className="alert-inner--text">
                          <strong>{t('Success')}!</strong> {success}
                        </span>
                      </UncontrolledAlert>
                    )}
                    {mainError && (
                      <UncontrolledAlert color="danger" fade={true}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{' '}
                        <span className="alert-inner--text">
                          <strong>{t('Danger')}!</strong> {mainError}
                        </span>
                      </UncontrolledAlert>
                    )}
                  </Col>
                </Row>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
export default withTranslation()(DietaryForm)
