import React, { useRef, useState } from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { validateFunc } from '../../constraints/constraints'
import { withTranslation } from 'react-i18next'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap'
// core components
import {
  createVendor,
  editVendor,
  getVendors
} from '../../apollo/server'

const CREATE_VENDOR = gql`
  ${createVendor}
`
const EDIT_VENDOR = gql`
  ${editVendor}
`
const GET_VENDORS = gql`
  ${getVendors}
`

function Vendor(props) {
  const formRef = useRef()
  const [restaurant, restaurantSetter] = useState(
    props.vendor && props.vendor.restaurants.length
      ? props.vendor.restaurants.map(({ _id, name }) => ({
          _id: _id,
          name,
          nameError: null
        }))
      : [
          {
            _id: '',
            name: '',
            nameError: null
          }
        ]
  )
  const mutation = props.vendor ? EDIT_VENDOR : CREATE_VENDOR
  const email = props.vendor ? props.vendor.email : ''
  const length = props.vendor ? props.vendor.restaurants.length : 1
  const [error, errorSetter] = useState('')
  const [success, successSetter] = useState('')
  const [emailError, emailErrorSetter] = useState(null)
  const [passError, passErrorSetter] = useState(null)
  const { t } = props

  const onBlur = (setter, field, state) => {
    setter(!validateFunc({ [field]: state }, field))
  }
  const handleChange = (value, index, type) => {
    let error = false
    const restaurants = restaurant
    if (type === 'name') {
      restaurants[index][type] =
        value.length === 1 ? value.toUpperCase() : value
      const occ = restaurants.filter(v => v.name === restaurants[index][type])
      if (occ.length > 1) error = true
    }
    restaurants[index][type + 'Error'] = error
      ? !error
      : !validateFunc({ [type]: restaurants[index][type] }, type)
    restaurantSetter([...restaurants])
  }

  const onAdd = index => {
    const restaurants = restaurant
    if (index === restaurants.length - 1) {
      restaurants.push({ _id: '', name: '', nameError: null })
    } else {
      restaurants.splice(index + 1, 0, {
        _id: '',
        name: '',
        nameError: null
      })
    }
    restaurantSetter([...restaurants])
  }
  const onRemove = index => {
    if (restaurant.length === 1 && index === 0) {
      return
    }
    const restaurants = restaurant
    restaurants.splice(index, 1)
    restaurantSetter([...restaurants])
  }
  const onSubmitValidaiton = () => {
    const emailError = !validateFunc(
      { email: formRef.current['input-email'].value },
      'email'
    )
    const passwordError = props.vendor
      ? true
      : !validateFunc(
          { password: formRef.current['input-password'].value },
          'password'
        )
    const restaurants = restaurant

    restaurants.map(ItemRestaurant => {
      let error = false

      const occ = restaurants.filter(v => v.name === ItemRestaurant.name)
      if (occ.length > 1) {
        error = true
      }
      ItemRestaurant.nameError = error
        ? !error
        : !validateFunc({ name: ItemRestaurant.name }, 'name')
      return ItemRestaurant
    })
    const restaurantsError = !restaurants.filter(
      restaurant => !restaurant.nameError
    ).length

    emailErrorSetter(emailError)
    passErrorSetter(passwordError)
    restaurantSetter([...restaurant])
    return (
      emailError && passwordError && restaurantsError
    )
  }
  const clearFields = () => {
    formRef.current.reset()
    emailErrorSetter('')
    passErrorSetter('')
    restaurantSetter([
      {
        _id: '',
        name: '',
        nameError: null
      }
    ])
  }
  const onCompleted = data => {
    if (!props.vendor) clearFields()
    const message = props.vendor
      ? 'Vendor updated successfully'
      : 'Vendor added successfully'
    errorSetter('')
    successSetter(message)
    setTimeout(hideAlert, 5000)
  }

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('')
    if (graphQLErrors) errorSetter(graphQLErrors[0].message)
    else if (networkError) errorSetter(networkError.result.errors[0].message)
    else errorSetter('Something went wrong!')
    setTimeout(hideAlert, 5000)
  }

  const hideAlert = () => {
    errorSetter('')
    successSetter('')
  }

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {props.vendor ? t('Edit Vendor') : t('Add Vendor')}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Mutation
              mutation={mutation}
              onCompleted={onCompleted}
              onError={onError}
              refetchQueries={[{ query: GET_VENDORS }]}>
              {(mutate, { loading: mutationLoading, error }) => {
                return (
                  <form ref={formRef}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-email">
                            {t('Email')}
                          </label>
                          <FormGroup
                            className={
                              emailError === null
                                ? ''
                                : emailError
                                ? 'has-success'
                                : 'has-danger'
                            }>
                            <Input
                              className="form-control-alternative text-lowercase"
                              id="input-email"
                              name="input-email"
                              placeholder="e.g vendor@gmail.com"
                              type="email"
                              defaultValue={email}
                              onBlur={event =>
                                onBlur(
                                  emailErrorSetter,
                                  'email',
                                  event.target.value
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        {!props.vendor && (
                          <Col lg="6">
                            <label
                              className="form-control-label"
                              htmlFor="input-password">
                              {t('Password')}
                            </label>
                            <FormGroup
                              className={
                                passError === null
                                  ? ''
                                  : passError
                                  ? 'has-success'
                                  : 'has-danger'
                              }>
                              <Input
                                className="form-control-alternative"
                                id="input-password"
                                name="input-password"
                                placeholder="e.g 132"
                                type="text"
                                onBlur={event => {
                                  onBlur(
                                    passErrorSetter,
                                    'password',
                                    event.target.value
                                  )
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col lg="6">
                          <h3 className="mb-0">{t('Restaurants')}</h3>
                          <br />
                        </Col>
                      </Row>
                      {restaurant.map((ItemRestaurant, index) => (
                        <div key={index}>
                          <Row>
                            <Col lg="12">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-name">
                                {t("Restaurant's Name")}
                              </label>
                              <br />
                              <FormGroup
                                className={
                                  ItemRestaurant.nameError === false
                                    ? 'has-danger'
                                    : ItemRestaurant.nameError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  className="form-control-alternative text-capitalize"
                                  defaultValue={ItemRestaurant.name}
                                  id="input-type-name"
                                  name="input-type-name"
                                  placeholder="e.g Johns Food"
                                  type="text"
                                  autoComplete="off"
                                  onBlur={event => {
                                    handleChange(
                                      event.target.value,
                                      index,
                                      'name'
                                    )
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {restaurant.length - 1 === index && (
                            <Row className="mt-2 ">
                              <Col lg="6">
                                <Row>
                                  {restaurant.length > length &&
                                    restaurant.length - 1 === index && (
                                      <FormGroup className="ml-2">
                                        <Button
                                          color="danger"
                                          onClick={() => {
                                            onRemove(index)
                                          }}>
                                          -
                                        </Button>
                                      </FormGroup>
                                    )}
                                  <FormGroup className="ml-2">
                                    <Button
                                      onClick={() => {
                                        onAdd(index)
                                      }}
                                      color="primary">
                                      +
                                    </Button>
                                  </FormGroup>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </div>
                      ))}

                      <Row>
                        <Col className="text-right" lg="6">
                          <Button
                            disabled={mutationLoading}
                            color="primary"
                            href="#pablo"
                            onClick={async e => {
                              e.preventDefault()
                              if (onSubmitValidaiton() && !mutationLoading) {
                                mutate({
                                  variables: {
                                    vendorInput: {
                                      _id: props.vendor ? props.vendor._id : '',
                                      email: formRef.current[
                                        'input-email'
                                      ].value.toLowerCase(),
                                      password: formRef.current[
                                        'input-password'
                                      ]
                                        ? formRef.current['input-password']
                                            .value
                                        : '',
                                      restaurants: restaurant.map(
                                        ({ _id, name }) => {
                                          return { _id, name }
                                        }
                                      )
                                    }
                                  }
                                })
                              }
                            }}
                            size="md">
                            {props.vendor ? 'Update' : t('Save')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </form>
                )
              }}
            </Mutation>
            <Row>
              <Col lg="6">
                {success && (
                  <UncontrolledAlert color="success" fade={true}>
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>{' '}
                    <span className="alert-inner--text">
                      <strong>{t('Success')}!</strong> {success}
                    </span>
                  </UncontrolledAlert>
                )}
                {error && (
                  <UncontrolledAlert color="danger" fade={true}>
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>{' '}
                    <span className="alert-inner--text">
                      <strong>{t('Danger')}!</strong> {error}
                    </span>
                  </UncontrolledAlert>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
export default withTranslation()(Vendor)
