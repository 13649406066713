import React from 'react'
import Loader from 'react-loader-spinner'
import { Mutation, Query } from 'react-apollo'
import DataTable from 'react-data-table-component'
import { Badge, Card, Row } from 'reactstrap'
import orderBy from 'lodash/orderBy'
import { handleErrors } from '../../utils/handleErrors'

import { GET_DIETS, DELETE_DIET_BY_NAME } from '../../apollo/dietary'
import CustomLoader from '../Loader/CustomLoader'

export const DietaryList = ({ translate, toggleModal }) => {
  const actionButtons = row => {
    return (
      <>
        <Mutation
          mutation={DELETE_DIET_BY_NAME}
          refetchQueries={[{ query: GET_DIETS }]}>
          {(deleteCategory, { loading: deleteLoading }) => {
            if (deleteLoading) {
              return (
                <Loader
                  type="ThreeDots"
                  color="#BB2124"
                  height={20}
                  width={40}
                  visible={deleteLoading}
                />
              )
            }
            return (
              <Badge
                href="#pablo"
                color="danger"
                onClick={e => {
                  e.preventDefault()
                  deleteCategory({ variables: { name: row.name } })
                }}>
                {'Delete'}
              </Badge>
            )
          }}
        </Mutation>
      </>
    )
  }

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const columns = [
    {
      name: 'ID',
      sortable: false,
      selector: 'id'
    },
    {
      name: 'Name',
      sortable: true,
      selector: 'name'
    },
    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>
    }
  ]

  const normalizeDietaryData = data => {
    const ids = data.restaurantsFilters
      ? data.restaurantsFilters.dietary.keys
      : []
    const names = data.restaurantsFilters
      ? data.restaurantsFilters.dietary.values
      : []

    const dataNormalized = names.map((name, idx) => ({ id: ids[idx], name }))

    return dataNormalized
  }

  return (
    <Row className="mt-5">
      <div className="col">
        <Card className="shadow">
          <Query query={GET_DIETS} onError={console.error}>
            {({ loading, error, data }) => {
              console.log('data HOC', data)

              if (error) {
                const errorMsg = handleErrors(error.graphQLErrors)
                return (
                  <span>
                    {translate('Error')}! {errorMsg}
                  </span>
                )
              }
              return (
                <DataTable
                  title={'Diets'}
                  columns={columns}
                  data={data ? normalizeDietaryData(data) : []}
                  pagination
                  progressPending={loading}
                  progressComponent={<CustomLoader />}
                  onSort={handleSort}
                  sortFunction={customSort}
                  defaultSortField="name"
                />
              )
            }}
          </Query>
        </Card>
      </div>
    </Row>
  )
}
