import React, { useState } from 'react'
import { compose, withApollo } from 'react-apollo'
import { Container, Modal } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import DietaryForm from '../components/Dietary/DietaryForm'
import Header from '../components/Headers/Header'
import { DietaryList } from '../components/Dietary/DietaryList'

const Dietary = props => {
  const [editModal, setEditModal] = useState(false)
  const [category, setCategory] = useState(null)

  const toggleModal = category => {
    setEditModal(!editModal)
    setCategory(category)
  }

  const { t } = props

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <DietaryForm />

        {/* Table */}
        <DietaryList translate={t} toggleModal={toggleModal} />

        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal(null)
          }}>
          <DietaryForm category={category} />
        </Modal>
      </Container>
    </>
  )
}
export default compose(withApollo, withTranslation())(Dietary)
