import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Mutation, Query } from 'react-apollo'
import { withTranslation } from 'react-i18next'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Col,
  Alert,
  ListGroup,
  ListGroupItem,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import { validateFunc } from '../../constraints/constraints'
import { updateOrderStatus, getConfiguration } from '../../apollo/server'
import Loader from 'react-loader-spinner'

// constants
const UPDATE_STATUS = gql`
  ${updateOrderStatus}
`

const GET_CONFIGURATION = gql`
  ${getConfiguration}
`

function Order(props) {
  const { order } = props
  const [reason, reasonSetter] = useState('')
  const [reasonError, reasonErrorSetter] = useState(null)
  const [error, errorSetter] = useState('')
  const [success, successSetter] = useState('')

  const validateReason = () => {
    const reasonError = !validateFunc({ reason }, 'reason')
    reasonErrorSetter(reasonError)
    return reasonError
  }
  const onCompleted = ({ updateOrderStatus }) => {
    if (updateOrderStatus) {
      successSetter('Order status updated')
    }
  }
  const onError = error => {
    errorSetter(error.message)
  }

  const onDismiss = () => {
    errorSetter('')
    successSetter('')
  }

  const { t } = props
  if (!props.order) return null

  const specialRequests = []
  order.items.forEach(item => {
    if (item.specialRequest) {
      specialRequests.push({ request: item.specialRequest, title: item.title })
    }
  })

  console.log({ order })

  const feeRows = [
    {
      label: 'Subtotal',
      value: parseFloat(
        order.orderAmount -
          order.deliveryCharges -
          order.driverTipping -
          order.restaurantPrepTipping -
          order.taxationAmount -
          order.mileageFees -
          (order.webServiceFee || 0) -
          (order.selectedSubscription || 0)
      ).toFixed(2)
    },
    {
      label: 'Driver Tip',
      value: order.driverTipping.toFixed(2)
    },
    {
      label: 'Restaurant Tip',
      value: order.restaurantPrepTipping.toFixed(2)
    },
    {
      label: 'Tax Charges',
      value: order.taxationAmount.toFixed(2)
    },
    {
      label: 'Mileage Fees',
      value: order.mileageFees.toFixed(2)
    },
    {
      label: 'Delivery Charges',
      value: order.deliveryCharges.toFixed(2)
    },
    {
      label: 'Web Service Fee',
      value: order.webServiceFee.toFixed(2)
    },
    {
      label: 'Subscription Plan Payment',
      value: order.selectedSubscription.toFixed(2)
    },
    {
      label: 'Total',
      value: order.orderAmount.toFixed(2)
    }
  ]

  const renderFeeRow = (row, configuration) => (
    <ListGroupItem className="justify-content-between" key={row.label}>
      {row.label}
      <Badge
        style={{
          fontSize: '12px',
          color: 'black',
          float: 'right'
        }}
        pill>
        {configuration.currencySymbol} {row.value}
      </Badge>
    </ListGroupItem>
  )

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">
              {t('Order')}#{order.orderId}
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form>
          <div className="pl-lg-4">
            {(error || success) && (
              <Row>
                <Col lg="12">
                  <Alert
                    color="success"
                    isOpen={!!success}
                    fade={true}
                    toggle={onDismiss}>
                    <span className="alert-inner--text">{success}</span>
                  </Alert>
                  <Alert
                    color="danger"
                    isOpen={!!error}
                    fade={true}
                    toggle={onDismiss}>
                    <span className="alert-inner--text">{error}</span>
                  </Alert>
                </Col>
              </Row>
            )}
            {order.orderStatus !== 'CANCELLED' &&
              order.orderStatus !== 'DELIVERED' && (
                <Row className="mb-2">
                  <Col lg="12">
                    <div>
                      <Mutation
                        mutation={UPDATE_STATUS}
                        onCompleted={onCompleted}
                        onError={onError}>
                        {(mutateStatus, { loading, error }) => {
                          if (loading) {
                            return (
                              <Loader
                                className="text-center"
                                type="TailSpin"
                                color="#fb6340"
                                height={40}
                                width={40}
                                visible={loading}
                              />
                            )
                          }
                          return (
                            <FormGroup
                              className={
                                reasonError === null
                                  ? ''
                                  : reasonError
                                  ? 'has-success'
                                  : 'has-danger'
                              }>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <Button
                                    disabled={
                                      order.orderStatus !== 'CANCELLED' &&
                                      order.orderStatus !== 'PENDING'
                                    }
                                    color="success"
                                    onClick={() => {
                                      mutateStatus({
                                        variables: {
                                          id: order._id,
                                          status: 'ACCEPTED',
                                          reason: ''
                                        }
                                      })
                                    }}>
                                    {order.status === true
                                      ? 'Accepted'
                                      : 'Accept'}
                                  </Button>
                                </InputGroupAddon>
                                <Input
                                  style={{ marginLeft: '5px' }}
                                  placeholder="Reason if rejected..."
                                  value={order.reason || reason}
                                  // readOnly={status === false}
                                  onChange={event => {
                                    reasonSetter(event.target.value)
                                  }}
                                  maxLength={20}
                                />
                                <InputGroupAddon addonType="append">
                                  <Button
                                    disabled={order.orderStatus === 'CANCELLED'}
                                    color="danger"
                                    onClick={() => {
                                      if (validateReason()) {
                                        mutateStatus({
                                          variables: {
                                            id: order._id,
                                            status: 'CANCELLED',
                                            reason: order.reason
                                          }
                                        })
                                      }
                                    }}>
                                    {order.status === false
                                      ? 'Cancelled'
                                      : 'Cancel'}
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          )
                        }}
                      </Mutation>
                    </div>
                  </Col>
                </Row>
              )}
            <Query query={GET_CONFIGURATION}>
              {({ data, loading, error }) => {
                if (loading) return null
                if (error) return null
                return (
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-items">
                        {t('Items')}
                      </label>
                      <FormGroup>
                        <ListGroup id="input-items">
                          {order.items.map(item => {
                            return (
                              <ListGroupItem
                                key={item._id}
                                className="justify-content-between">
                                <Badge
                                  style={{
                                    fontSize: '12px',
                                    backgroundColor: 'grey',
                                    marginRight: '10px'
                                  }}
                                  pill>
                                  {item.quantity}
                                </Badge>
                                {`${item.title}(${item.variation.title})`}
                                <Badge
                                  style={{
                                    fontSize: '12px',
                                    backgroundColor: 'black',
                                    float: 'right'
                                  }}
                                  pill>
                                  {data.configuration.currencySymbol}{' '}
                                  {(
                                    item.variation.price * item.quantity
                                  ).toFixed(2)}
                                </Badge>
                                {!!item.addons.length && (
                                  <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                      Addons
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {item.addons.map(addon => {
                                        return addon.options.map(
                                          (option, index) => (
                                            <DropdownItem key={index}>
                                              {addon.title}:- {option.title}{' '}
                                              <Badge
                                                style={{
                                                  fontSize: '12px',
                                                  backgroundColor: 'black',
                                                  float: 'right'
                                                }}
                                                pill>
                                                {
                                                  data.configuration
                                                    .currencySymbol
                                                }{' '}
                                                {option.price}
                                              </Badge>
                                            </DropdownItem>
                                          )
                                        )
                                      })}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )}
                              </ListGroupItem>
                            )
                          })}
                        </ListGroup>
                        {specialRequests.length > 0 ? (
                          <div>
                            {specialRequests.map((request, i) => (
                              <div key={i}>
                                {`${request.title} : ${request.request}`}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <span>{'No special requests from customer'}</span>
                        )}
                        <div>
                          <span>Comments:</span>
                          <div>
                            <span>
                              {order.comment
                                ? order.comment
                                : 'No comments from customer'}
                            </span>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-price">
                            {t('Charges')}
                          </label>
                          <FormGroup>
                            <ListGroup id="input-price">
                              {feeRows.map(row =>
                                renderFeeRow(row, data.configuration)
                              )}
                            </ListGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-payment">
                            {t('Payment')}
                          </label>
                          <FormGroup>
                            <ListGroup id="input-payment">
                              <ListGroupItem className="justify-content-between">
                                Payment Method
                                <Badge
                                  style={{
                                    fontSize: '12px',
                                    backgroundColor: 'green',
                                    float: 'right'
                                  }}
                                  pill>
                                  {order.paymentMethod}
                                </Badge>
                              </ListGroupItem>
                              <ListGroupItem className="justify-content-between">
                                Paid Amount
                                <Badge
                                  style={{
                                    fontSize: '12px',
                                    float: 'right',
                                    color: 'black'
                                  }}>
                                  {data.configuration.currencySymbol}{' '}
                                  {order.orderAmount
                                    ? order.orderAmount.toFixed(2)
                                    : 0}
                                </Badge>
                              </ListGroupItem>
                            </ListGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              }}
            </Query>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}
export default withTranslation()(Order)
