export const handleErrors = errors => {
  console.error('GraphQL errors info:', errors)

  const errorMsg = errors[0].message

  if (errorMsg.includes('Unauthenticated')) {
    return 'You have not selected a restaurant.'
  }

  if (errorMsg.includes('Cast to ObjectId failed')) {
    return 'Restaurant not found.'
  }

  return 'An unexpected error has occurred, please contact the support team..'
}
