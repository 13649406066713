import React from 'react'
import Restaurants from '../components/Restaurant/Card'
import { withRouter, Link } from 'react-router-dom'
import { Container, Row } from 'reactstrap'

import Header from '../components/Headers/Header'
import { Query, withApollo } from 'react-apollo'
import { restaurantByOwner } from '../apollo/server'
import gql from 'graphql-tag'
import { handleErrors } from '../utils/handleErrors'
const RESTAURANT_BY_OWNER = gql`
  ${restaurantByOwner}
`

const Restaurant = props => {
  console.log('propss', props.location)
  const id = props.location.state ? props.location.state.id : ''
  props.client.query({ query: RESTAURANT_BY_OWNER }, onCompleted, onError)
  function onCompleted(data) {
    console.log('onCompleted', data)
  }
  function onError(error) {
    console.log('error', error)
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Query query={RESTAURANT_BY_OWNER} variables={{ id: id }}>
            {({ loading, error, data }) => {
              // eslint-disable-next-line no-unused-expressions

              if (loading) {
                return <div>{loading}</div>
              }
              if (error) {
                const errorMsg = handleErrors(error.graphQLErrors)
                return (
                  <div>
                    <span>{errorMsg}</span>
                  </div>
                )
              }
              console.log(data)
              return data.restaurantByOwner.restaurants.map((rest, index) => {
                return (
                  <Link
                    key={rest._id}
                    onClick={() =>
                      localStorage.setItem('restaurant_id', rest._id)
                    }
                    to={'/admin/dashboard'}>
                    <Restaurants key={rest._id} rest={rest} />
                  </Link>
                )
              })
            }}
          </Query>
        </Row>
      </Container>
    </>
  )
}

export default withRouter(withApollo(Restaurant))
