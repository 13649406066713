import gql from 'graphql-tag'

export const CREATE_DIET = gql`
  mutation CreateDietary($name: String!) {
    createDietary(dietary: { name: $name }) {
      name
    }
  }
`

export const GET_DIETS = gql`
  {
    restaurantsFilters {
      dietary {
        keys
        values
      }
    }
  }
`

export const DELETE_DIET_BY_NAME = gql`
  mutation DeleteDietary($name: String!) {
    deleteDietary(name: $name)
  }
`
