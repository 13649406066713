import React from 'react'
import { compose, withApollo } from 'react-apollo'
import { withTranslation } from 'react-i18next'
import { Card, Container, Row, Col } from 'reactstrap'
import DayComponent from './TimingBox'

const Timings = ({ timings, setTimings, ...props }) => {
  const { t } = props

  const onChangeTime = (day, values) => {
    timings[day] = values
    setTimings({ ...timings })
  }
  if (!timings) return <></>

  return (
    <Container className="mt--7">
      <Row className="mt-5">
        <div className="col">
          <Card className="shadow">
            <form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <label className="form-control-label">{t('Day')}</label>
                  </Col>
                  <Col lg="6">
                    <label className="form-control-label">
                      {t('Open Times')}
                    </label>
                  </Col>
                </Row>

                <DayComponent
                  canAddMultiple={false}
                  resetTitle="Not Available All Day"
                  startTitle="Available"
                  day={t('MON')}
                  values={timings.MON || [['00:00', '23:59']]}
                  onChangeTime={onChangeTime}
                />
                <DayComponent
                  canAddMultiple={false}
                  resetTitle="Not Available All Day"
                  startTitle="Available"
                  day={t('TUE')}
                  values={timings.TUE || [['00:00', '23:59']]}
                  onChangeTime={onChangeTime}
                />
                <DayComponent
                  canAddMultiple={false}
                  resetTitle="Not Available All Day"
                  startTitle="Available"
                  day={t('WED')}
                  values={timings.WED || [['00:00', '23:59']]}
                  onChangeTime={onChangeTime}
                />
                <DayComponent
                  canAddMultiple={false}
                  resetTitle="Not Available All Day"
                  startTitle="Available"
                  day={t('THU')}
                  values={timings.THU || [['00:00', '23:59']]}
                  onChangeTime={onChangeTime}
                />
                <DayComponent
                  canAddMultiple={false}
                  resetTitle="Not Available All Day"
                  startTitle="Available"
                  day={t('FRI')}
                  values={timings.FRI || [['00:00', '23:59']]}
                  onChangeTime={onChangeTime}
                />
                <DayComponent
                  canAddMultiple={false}
                  resetTitle="Not Available All Day"
                  startTitle="Available"
                  day={t('SAT')}
                  values={timings.SAT || [['00:00', '23:59']]}
                  onChangeTime={onChangeTime}
                />
                <DayComponent
                  canAddMultiple={false}
                  resetTitle="Not Available All Day"
                  startTitle="Available"
                  day={t('SUN')}
                  values={timings.SUN || [['00:00', '23:59']]}
                  onChangeTime={onChangeTime}
                />
              </div>
            </form>
          </Card>
        </div>
      </Row>
    </Container>
  )
}

export default compose(withApollo, withTranslation())(Timings)
