export const createFood = `
  mutation CreateFood($foodInput:FoodInput!){
      createFood(
          foodInput:$foodInput
      ){
        _id
      categories{
        _id
        title
        foods{
          _id
          title
          description
          variations{
            _id
            title
            price
            discounted
            addons
          }
          image
          isActive
        }
        createdAt
        updatedAt
      }
      }
    }`

export const editFood = `
    mutation EditFood($foodInput:FoodInput!){
        editFood(
            foodInput:$foodInput
        ){
            _id
            categories{
              _id
              title
              foods{
                _id
                title
                description
                variations{
                  _id
                  title
                  price
                  discounted
                  addons
                }
                image
                isActive
              }
              createdAt
              updatedAt
            }
        }
      }`

export const deleteFood = `
      mutation DeleteFood($id:String!,$restaurant:String!,$categoryId:String!){
        deleteFood(id:$id,restaurant:$restaurant,categoryId:$categoryId){
          _id
          categories{
            _id
            title
            foods{
              _id
              title
              description
              variations{
                _id
                title
                price
                discounted
                addons
              }
              image
              isActive
            }
            createdAt
            updatedAt
          }
        }
      }`

export const createCategory = `
mutation CreateCategory($category:CategoryInput){
  createCategory(category:$category){
    _id
    categories{
      _id
      title
      foods{
        _id
        title
        description
        variations{
          _id
          title
          price
          discounted
          addons
        }
        image
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}`

export const editCategory = `
      mutation EditCategory($category:CategoryInput){
        editCategory(category:$category){
          _id
          categories{
            _id
            title
            foods{
              _id
              title
              description
              variations{
                _id
                title
                price
                discounted
                addons
              }
              image
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }`

export const deleteCategory = `
      mutation DeleteCategory($id:String!,$restaurant:String!){
        deleteCategory(id:$id,restaurant:$restaurant){
                _id
                categories{
                  _id
                  title
                  foods{
                    _id
                    title
                    description
                    variations{
                      _id
                      title
                      price
                      discounted
                      addons
                    }
                    image
                    isActive
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
        }
      }`
export const getOrders = `query Orders($page:Int){
  allOrders(page:$page){
    _id
    deliveryAddress
    deliveryCharges
    orderAmount
    paidAmount
    paymentMethod
    orderId
    user{
      _id
      name
      email
      phone
    }
    items{
      _id
      food{
        _id
        title
        description
        image
      }
      variation{
        _id
        title
        price
        discounted
      }
      addons{
        _id
        title
        description
        quantityMinimum
        quantityMaximum
        options{
          _id
          title
          price
        }
      }
      quantity
    }
    reason
    status
    paymentStatus
    orderStatus
    createdAt
    review{
      _id
      rating
      description
    }
    rider{
      _id
      name
    }
  }
}`

export const reviews = `query Reviews($restaurant:String!){
  reviews(restaurant:$restaurant){
    _id
    order{
      _id
      orderId
      items{
        title
      }
      user{
        _id
        name
        email
      }
    }
    restaurant{
      _id
      name
      image
    }
    restaurantRating
    restaurantDescription
    createdAt
  }
}`

export const getOrdersByRestaurant = `query ordersByRestId($restaurant:String!,$page:Int,$rows:Int,$search:String){
  ordersByRestId(restaurant:$restaurant,page:$page,rows:$rows,search:$search){
    _id
    orderId
    restaurant{
      _id
      name
      image
      address
      location{coordinates}
      deliveryCharges
    }
    deliveryAddress{
      location{coordinates}
      deliveryAddress
      details
      label
    }
    items{
      _id
      title
      description
      image
      quantity
      variation{
        _id
        title
        price
        discounted
      }
      addons{
        _id
        options{
          _id
          title
          description
          price
        }
        description
        title
        quantityMinimum
        quantityMaximum
      }
      isActive
      createdAt
      updatedAt
    }
    user{
      _id
      name
      phone
      email
    }
    paymentMethod
    paidAmount
    orderAmount
    deliveryCharges
    restaurantPrepTipping
    driverTipping
    mileageFees
    taxationAmount
    webServiceFee
    selectedSubscription
    orderStatus
    status
    paymentStatus
    reason
    isActive
    createdAt
    rider{
      _id
      name
      username
      available
      location{
        coordinates
      }
    }
  }
}`

export const getDashboardTotal = `query GetDashboardTotal($startingDate: String, $endingDate: String,$restaurant:String!){
  getDashboardTotal(starting_date: $startingDate, ending_date: $endingDate,restaurant:$restaurant){
    totalOrders
    totalSales
  }
}`
export const getDashboardSales = `query GetDashboardSales($startingDate: String, $endingDate: String,$restaurant:String!){
  getDashboardSales(starting_date: $startingDate, ending_date: $endingDate,restaurant:$restaurant){
    orders{
      day
      amount
    }
  }
}`
export const getDashboardOrders = `query GetDashboardOrders($startingDate: String, $endingDate: String,$restaurant:String!){
  getDashboardOrders(starting_date: $startingDate, ending_date: $endingDate,restaurant:$restaurant){
    orders{
      day
      count
    }
  }
}`

export const getDashboardData = `query GetDashboardData($startingDate: String, $endingDate: String){
  getDashboardData(starting_date: $startingDate, ending_date: $endingDate){
    totalOrders
    totalUsers
    totalSales
    orders{
      day
      count
      amount
    }
  }
}`

export const getConfiguration = `query GetConfiguration{
  configuration{
    _id
    email
    password
    enableEmail
    clientId
    clientSecret
    sandbox
    publishableKey
    secretKey
    currency
    currencySymbol
  }
}`

export const saveEmailConfiguration = `mutation SaveEmailConfiguration($configurationInput:EmailConfigurationInput!){
  saveEmailConfiguration(configurationInput:$configurationInput){
    _id
    email
    password
    enableEmail
  }
}`

export const savePaypalConfiguration = `mutation SavePaypalConfiguration($configurationInput:PaypalConfigurationInput!){
  savePaypalConfiguration(configurationInput:$configurationInput){
    _id
    clientId
    clientSecret
    sandbox
  }
}`

export const saveStripeConfiguration = `mutation SaveStripeConfiguration($configurationInput:StripeConfigurationInput!){
  saveStripeConfiguration(configurationInput:$configurationInput){
    _id
    publishableKey
    secretKey
  }
}`

export const saveCurrencyConfiguration = `mutation SaveCurrencyConfiguration($configurationInput:CurrencyConfigurationInput!){
  saveCurrencyConfiguration(configurationInput:$configurationInput){
    _id
    currency
    currencySymbol
  }
}`

export const ownerLogin = `mutation ownerLogin($email:String!,$password:String!){
  ownerLogin(email:$email,password:$password){
    userId
    token
    email
    userType
    restaurants{
      _id
      orderId
      name
      image
      address
    }
  }
}`

export const restaurantByOwner = `query RestaurantByOwner($id:String){
  restaurantByOwner(id:$id){
  _id
  email
  userType
    restaurants{
      _id
      orderId
      name
      image
      address
      deliveryTime
      minimumOrder
      zone{
        _id
        title
      }
    }
  }
}`

export const restaurantList = `query RestaurantList{
  restaurantList{
    _id
    name
    address
  }
}`

export const restaurants = `query Restaurants($filter:String, $latitude:Float, $longitude:Float, $city:String){
  restaurants(filter:$filter, latitude:$latitude, longitude:$longitude, city:$city){
    _id
    name
    image
    orderPrefix
    address
    deliveryTime
    minimumOrder
    zone{
      _id
      title
    }
    featured
    isActive,
    isAvailable
  }
}
`

export const getRestaurantProfile = `query Restaurant($id:String){
      restaurant(id:$id)
      {
      _id
      orderId
      orderPrefix
      name
      phone
      phoneSMSNotification
      image
      address
      location{coordinates}
      deliveryCharges
      username
      password
      deliveryTime
      minimumOrder
      tax
      zone{
        _id
        title
      }
      isAvailable
      openingTimes{
        day
        times{
          startTime
          endTime
        }
      }
    }
}`

export const getRestaurantDetail = `query Restaurant($id:String){
      restaurant(id:$id){
      _id
      orderId
      orderPrefix
      name
      image
      address
      location{coordinates}
      deliveryCharges
      deliveryTime
      minimumOrder
      zone{
        _id
        title
      }
      categories{
        _id
        title
        type
        foods{
          _id
          title
          description
          variations{
            _id
            title
            price
            discounted
            addons
          }
          image
          isActive
        }
        timings{
          day
          times{
            startTime
            endTime
          }
        }
      }
      usedCategory
      options{
        _id
        title
        description
        price
      }
      addons{
        _id
        options
        title
        description
        quantityMinimum
        quantityMaximum
      }
      
    }
}`

export const getOffers = `query Offers{
  offers{
    _id
    name
    tag
    restaurants{
      _id
      name
    }
  }
}`

export const getDistinctCities = `query{
  getDistinctCities
}`

export const getSections = `query Sections{
  sections{
    _id
    name
    enabled
    restaurants{
      _id
      name
    }
  }
}`

export const createSection = `mutation CreateSection($section:SectionInput!){
  createSection(section:$section){
      _id
      name
      enabled
      restaurants{
        _id
        name
      }
    }
}`
export const editSection = `mutation editSection($section:SectionInput!){
  editSection(section:$section){
      _id
      name
      enabled
      restaurants{
        _id
        name
      }
    }
}`

export const deleteSection = `mutation DeleteSection($id:String!){
  deleteSection(id:$id)
}`

export const deleteVendor = `mutation DeleteVendor($id:String!){
  deleteVendor(id:$id)
}`

export const updateOrderStatus = `mutation UpdateOrderStatus($id:String!,$status:String!,$reason:String){
  updateOrderStatus(id:$id,status:$status,reason:$reason){
    _id
    orderStatus
  }
}
`
export const updateStatus = `mutation UpdateStatus($id:String!,$orderStatus:String!){
  updateStatus(id:$id,orderStatus:$orderStatus){
    _id
    orderStatus
  }
}
`

export const uploadToken = `mutation UploadToken($id:String!,$pushToken:String!){
  uploadToken(id:$id,pushToken:$pushToken){
    _id
    pushToken
  }
}`

export const getUsers = `query($filter:String){
  users(filter:$filter){
    _id
    name
    email
    phone
    subscribedExpiredDate
    subscribed
    addresses{
      location{coordinates}
      deliveryAddress
    }
  }
}`

export const updateDeliveryDate = `mutation UpdateDeliveryDate($newDate:String!, $userId: String!){
  updateDeliveryDate(newDate:$newDate, userId: $userId)
}`

export const resetPassword = `mutation ResetPassword($password:String!,$token:String!){
  resetPassword(password:$password,token:$token){
    result
  }
}`

export const createRider = `
mutation CreateRider($riderInput:RiderInput!){
    createRider(
        riderInput:$riderInput
    ){
    _id
    name
    username
    password
    phone
    available
      zone{
        _id
      }
    }
  }`

export const getRiders = `query{
  riders{
    _id
    name
    username
    password
    phone
    available
  }
}`

export const getAvailableRiders = `query{
  availableRiders{
    _id
    name
    username
    phone
    available
    zone{
      _id
    }
  }
}`

export const editRider = `
    mutation EditRider($riderInput:RiderInput!){
        editRider(
          riderInput:$riderInput
        ){
          _id
          name
          username
          phone
          zone{
            _id
          }
        }
      }`
export const deleteRider = `
      mutation DeleteRider($id:String!){
        deleteRider(id:$id){
          _id
        }
      }`

export const toggleAvailablity = `
      mutation ToggleRider($id:String){
        toggleAvailablity(id:$id){
          _id
        }
}`

export const pageCount = `
query PageCount($restaurant:String!){
  pageCount(restaurant:$restaurant)
}
`

export const assignRider = ` mutation AssignRider($id:String!,$riderId:String!){
  assignRider(id:$id,riderId:$riderId){
    _id
    rider{
      _id
      name
    }
  }
}`

export const getPaymentStatuses = `query{
  getPaymentStatuses
}`

export const updatePaymentStatus = `mutation UpdatePaymentStatus($id:String!,$status:String!){
  updatePaymentStatus(id:$id,status:$status){
    _id
    paymentStatus
    paidAmount
  }
}
`

export const createOffer = `mutation CreateOffer($offer:OfferInput!){
  createOffer(offer:$offer){
      _id
      name
      tag
      restaurants{
        _id
        name
        address
      }
    }
}`

export const editOffer = `mutation EditOffer($offer:OfferInput!){
  editOffer(offer:$offer){
      _id
      name
      tag
      restaurants{
        _id
        name
        address
      }
    }
}`

export const deleteOffer = `mutation DeleteOffer($id:String!){
  deleteOffer(id:$id)
}`

export const createOptions = `mutation CreateOptions($optionInput:CreateOptionInput){
  createOptions(optionInput:$optionInput){
    _id
    options{
      _id
      title
      description
      price
    }
  }
}`

export const getOptions = `query Options{
  options {
    _id
    title
    description
    price
  }
}
`

export const createAddons = `mutation CreateAddons($addonInput:AddonInput){
  createAddons(addonInput:$addonInput){
      _id
      addons{
        _id
        options
        title
        description
        quantityMinimum
        quantityMaximum
      }
      
    }
}`
export const editAddon = `mutation editAddon($addonInput:editAddonInput){
  editAddon(addonInput:$addonInput){
      _id
      addons{
        _id
        options
        title
        description
        quantityMinimum
        quantityMaximum
      }
  }
}`

export const getAddons = `query Addons{
  addons{
  _id
  title
  description
  options{
    _id
    title
    description
    price
  }
  quantityMinimum
  quantityMaximum
}}`

export const deleteAddon = `
      mutation DeleteAddon($id:String!,$restaurant:String!){
        deleteAddon(id:$id,restaurant:$restaurant){
          _id
          addons{
            _id
            options
            title
            description
            quantityMinimum
            quantityMaximum
          }
        }
      }`

export const deleteOption = `
      mutation DeleteOption($id:String!,$restaurant:String!){
        deleteOption(id:$id,restaurant:$restaurant){
          _id
          options{
            _id
            title
            description
            price
          }
        }
      }`
export const editOption = `mutation editOption($optionInput:editOptionInput){
  editOption(optionInput:$optionInput){
          _id
          options{
            _id
            title
            description
            price
          }
        }
      }`

export const createCoupon = `mutation CreateCoupon($couponInput:CouponInput!){
  createCoupon(couponInput:$couponInput){
    _id
    title
    discount
    enabled
  }
}`
export const editCoupon = `mutation editCoupon($couponInput:CouponInput!){
  editCoupon(couponInput:$couponInput){
        _id
        title
        discount
        enabled
        }
      }`
export const deleteCoupon = `mutation DeleteCoupon($id:String!){
        deleteCoupon(id:$id)
      }`

export const getCoupons = `query Coupons{
        coupons {
          _id
          title
          discount
          enabled
        }
      }`

export const getTipping = `query Tips{
        tips {
          _id
          tipVariations
          enabled
        }
      }`
export const createTipping = `mutation CreateTipping($tippingInput:TippingInput!){
        createTipping(tippingInput:$tippingInput){
          _id
          tipVariations
          enabled
        }
      }`
export const editTipping = `mutation editTipping($tippingInput:TippingInput!){
  editTipping(tippingInput:$tippingInput){
            _id
            tipVariations
            enabled
              }
            }`

export const getTaxation = `query Taxes{
      taxes {
        _id
        taxationCharges
        enabled
        }
      }`

export const createTaxation = `mutation CreateTaxation($taxationInput:TaxationInput!){
    createTaxation(taxationInput:$taxationInput){
          _id
        taxationCharges
        enabled
        }
      }`
export const editTaxation = `mutation editTaxation($taxationInput:TaxationInput!){
    editTaxation(taxationInput:$taxationInput){
            _id
            taxationCharges
            enabled
              }
            }`
export const createVendor = `mutation CreateVendor($vendorInput:VendorInput){
    createVendor(vendorInput:$vendorInput){
      _id
      email
      userType
      restaurants{
        _id
        orderId
        orderPrefix
        name
        image
        address
        location{coordinates}
        deliveryCharges
        zone{
          _id
          title
        }
      }
    }
}`

export const editVendor = `mutation EditVendor($vendorInput:VendorInput){
  editVendor(vendorInput:$vendorInput){
    _id
    email
    restaurants{
      _id
      orderId
      orderPrefix
      name
      image
      address
      location{coordinates}
      deliveryCharges
      zone{
        _id
        title
      }
    }
  }
}`

export const getVendors = `query Vendors{
    vendors{
      _id
      email
      userType
      restaurants{
        _id
        orderId
        orderPrefix
        name
        image
        address
        location{coordinates}
        deliveryCharges
        zone{
          _id
          title
        }
      }
    }
}`

export const getVendor = `query GetVendor($id:String!){
    getVendor(id:$id){
        _id
        email
        userType
        restaurants{
          _id
          orderId
          orderPrefix
          name
          image
          address
          location{coordinates}
          deliveryCharges
        }
    }
}`

export const editRestaurant = `mutation EditRestaurant($restaurantInput:RestaurantProfileInput!){
    editRestaurant(restaurant:$restaurantInput){
      _id
      orderId
      orderPrefix
      name
      image
      address
      username
      password
      location{coordinates}
      deliveryCharges
      zone{
        _id
        title
      }
      isAvailable
      openingTimes{
        day
        times{
          startTime
          endTime
        }
      }
    }
}`

export const createZone = `mutation CreateZone($zone:ZoneInput!){
  createZone(zone:$zone){
    _id
    title
    description
    location{coordinates}
    isActive
  }
}`

export const editZone = `mutation EditZone($zone:ZoneInput!){
  editZone(zone:$zone){
    _id
    title
    description
    location{coordinates}
    isActive
  }
}`

export const getZones = `query Zones{
    zones{
    _id
    title
    description
    location{coordinates}
    isActive
    }
}`

export const deleteZone = `mutation DeleteZone($id:String!){
  deleteZone(id:$id){
    _id
    title
    description
    location{coordinates}
    isActive
  }
}`

export const vendorResetPassword = `mutation VendorResetPassword($oldPassword: String!, $newPassword: String!){
    vendorResetPassword(oldPassword: $oldPassword, newPassword: $newPassword)
}`

export const subscribePlaceOrder = `subscription SubscribePaceOrder($restaurant:String!){
  subscribePlaceOrder(restaurant:$restaurant){
    userId
    origin
    order{
      _id
      orderId
      restaurant{
        _id
        name
        image
        address
        location{coordinates}
      }
      deliveryAddress{
        location{coordinates}
        deliveryAddress
        details
        label
      }
      items{
        _id
        title
        description
        image
        quantity
        variation{
          _id
          title
          price
          discounted
        }
        addons{
          _id
          options{
            _id
            title
            description
            price
          }
          description
          title
          quantityMinimum
          quantityMaximum
        }
        isActive
        createdAt
        updatedAt
      }
      user{
        _id
        name
        phone
        email
      }
      paymentMethod
      paidAmount
      orderAmount
      orderStatus
      status
      paymentStatus
      reason
      isActive
      createdAt
      deliveryCharges
      rider{
        _id
        name
        username
        available
      }
  }
}
}`

export const orderCount = `
query OrderCount($restaurant:String!){
  orderCount(restaurant:$restaurant)
}`

export const toggleFeatured = ` mutation ToggleFeatured($restaurantId:String!){
  toggleFeatured(restaurantId:$restaurantId){
    _id
    featured
  }
}`

export const toggleAvailabilityAdmin = ` mutation toggleAvailabilityAdmin($restaurantId:String!){
  toggleAvailabilityAdmin(restaurantId:$restaurantId){
    _id
    isAvailable
  }
}`

export const getActiveOrders = `query GetActiveOrders{
  getActiveOrders{
    _id
    orderId
    restaurant{
      _id
      name
      image
      address
      location{coordinates}
      deliveryCharges
    }
    deliveryAddress{
      location{coordinates}
      deliveryAddress
      details
      label
    }
    items{
      _id
      title
      description
      image
      quantity
      variation{
        _id
        title
        price
        discounted
      }
      addons{
        _id
        options{
          _id
          title
          description
          price
        }
        description
        title
        quantityMinimum
        quantityMaximum
      }
      isActive
      createdAt
      updatedAt
    }
    user{
      _id
      name
      phone
      email
    }
    paymentMethod
    paidAmount
    orderAmount
    restaurantPrepTipping
    driverTipping
    taxationAmount
    mileageFees
    deliveryCharges
    webServiceFee
    selectedSubscription
    orderStatus
    status
    paymentStatus
    isPickedUp
    reason
    isActive
    createdAt
    rider{
      _id
      name
      username
      available
      location{
        coordinates
      }
    }
  }
}`

export const getRidersByZone = `query RidersByZone($id:String!){
  ridersByZone(id:$id){
    _id
    name
    username
    password
    phone
    available
  }
}`

export const subscriptionOrder = `subscription SubscriptionOrder($id:String!){
  subscriptionOrder(id:$id){
      _id
      orderStatus
      rider{
          _id
      }
  }
}`
export const deleteRestaurant = `mutation DeltetRestaurant($id:String!){
  deleteRestaurant(id:$id){
    _id
    isActive
  }
}`

export const updateTimings = `mutation UpdateTimings($id:String!,$openingTimes:[TimingsInput]){
  updateTimings(id:$id,
    openingTimes:$openingTimes){
    _id
    openingTimes{
      day
      times{
        startTime
        endTime
      }
    }
  }
}`

export const sendNotificationUser = `mutation SendNotificationUser($notificationTitle:String, $notificationBody: String!){
  sendNotificationUser(notificationTitle:$notificationTitle,notificationBody:$notificationBody)
}
`
export const editCategoryTypeAdmin = ` mutation editCategoryTypeAdmin($restaurantId:String!, $type: CategoryType!){
  editCategoryTypeAdmin(restaurantId:$restaurantId, type: $type){
    _id
  }
}`