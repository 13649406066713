import React, { useState, useRef, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
// reactstrap components
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
// core components
import AdminNavbar from '../components/Navbars/AdminNavbar'
import AdminFooter from '../components/Footers/AdminFooter'
import Sidebar from '../components/Sidebar/Sidebar'

import routes from '../routes'

function Admin(props) {
  var divRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)

  const resetUser = () => {
    localStorage.removeItem('user-snap')
    localStorage.removeItem('restaurant_id')
    props.history.push('/auth/login')
  }

  const toogleModal = () => {
    setModalOpen(!modalOpen)
  }

  useEffect(() => {
    console.log('props', props)
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    divRef.current.scrollTop = 0

    const restaurantId = localStorage.getItem('restaurant_id')

    if (!restaurantId) toogleModal()

  }, [])

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name
      }
    }
    return 'Brand'
  }

  return (
    <>
      <Modal
        isOpen={modalOpen}
        toggle={() => { toogleModal(); resetUser() }}
      >
        <ModalHeader>
          <h3 className='danger'>Error</h3>
        </ModalHeader>

        <ModalBody>
          Login With Restaurant Credentials
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              toogleModal()
              resetUser()
            }}
            color='primary'
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: '/admin/dashboard',
          imgSrc: require('../assets/img/brand/logo.png'),
          imgAlt: '...'
        }}
      />
      <div className="main-content" ref={divRef}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>{getRoutes(routes)}</Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  )
}

export default Admin
