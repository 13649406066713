import React from 'react'

export const FooterSnap = ({ textWhite = false }) => {
  return (
    <footer class="w-full bg-transparent p-4 text-sm" style={{ marginTop: '70px' }}>
      <div class="max-w-7xl mx-auto 2xl:flex 2xl:justify-center 2xl:items-center 2xl:gap-x-3">
        <ul class="listLink__grid">
          <li>
            <a class={`hover_text-primary-500 ${textWhite ? 'text-white' : 'text-gray-800'}`} href="https://opportunity.snapdelivered.com/#about">About us</a>
          </li>
          <li>
            <a class={`hover_text-primary-500 ${textWhite ? 'text-white' : 'text-gray-800'}`} href="https://store.snapdelivered.com/">Merch Store</a>
          </li>
          <li>
            <a class={`hover_text-primary-500 ${textWhite ? 'text-white' : 'text-gray-800'}`} href="https://opportunity.snapdelivered.com/#contact">Opportunities with Snap Delivered</a>
          </li>
          <li>
            <a class={`hover_text-primary-500 ${textWhite ? 'text-white' : 'text-gray-800'}`} href="https://opportunity.snapdelivered.com/#contact">Support</a>
          </li>
          <li>
            <a class={`hover_text-primary-500 ${textWhite ? 'text-white' : 'text-gray-800'}`} href="https://snapdeliveredteam.com/legal/privacy-policy">Privacy policy</a>
          </li>
          <li>
            <a class={`hover_text-primary-500 ${textWhite ? 'text-white' : 'text-gray-800'}`} href="https://snapdeliveredteam.com/legal/terms-of-use">Terms of Use</a>
          </li>
        </ul>

        <section class="footer__apps">
          <ul class="flex justify-center items-center my-4 gap-x-4">
            <li class="bg-white rounded-full p-2 cursor-pointer footer__apps--item">
              <a href="https://snapdeliveredteam.com/download-app?device=APPLE">
                <svg class="w-6 h-6" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.8334 1.66699C13 2.58366 12.5834 3.50033 12.0834 4.16699C11.5834 4.83366 10.6667 5.41699 9.75004 5.33366C9.58337 4.41699 10 3.58366 10.5 2.91699C11.0834 2.25033 12 1.75033 12.8334 1.66699ZM15.5 16.167C15.9167 15.5003 16.1667 15.0837 16.5 14.3337C13.9167 13.3337 13.5 9.66699 16.0834 8.33366C15.3334 7.33366 14.25 6.83366 13.1667 6.83366C12.4167 6.83366 11.9167 7.00033 11.4167 7.16699C11 7.33366 10.6667 7.41699 10.25 7.41699C9.75004 7.41699 9.4167 7.25033 8.9167 7.08366C8.4167 6.91699 7.9167 6.75033 7.33337 6.75033C6.1667 6.75033 4.9167 7.41699 4.1667 8.66699C3.08337 10.3337 3.25004 13.5837 5.00004 16.2503C5.75004 17.2503 6.58337 18.3337 7.6667 18.3337C8.1667 18.3337 8.4167 18.167 8.75004 18.0837C9.1667 17.917 9.58337 17.7503 10.25 17.7503C11 17.7503 11.3334 17.917 11.75 18.0837C12.0834 18.2503 12.3334 18.3337 12.8334 18.3337C14 18.3337 14.8334 17.0837 15.5 16.167Z" stroke="#E35C49" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </a>
            </li>
            
            <li class="bg-white rounded-full p-2 cursor-pointer footer__apps--item">
              <a href="https://snapdeliveredteam.com/download-app?device=ANDROID">
                <svg class="w-6 h-6" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.8334 10.7497L14.0834 12.6663L11.4167 9.99967L14.0834 7.33301L17.8334 9.16634C18.5001 9.49967 18.5001 10.4997 17.8334 10.7497Z" stroke="#E35C49" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.0834 7.33342L11.4167 10.0001L3.41675 18.0001L2.91675 18.2501C2.33341 18.5834 1.66675 18.0834 1.66675 17.4168V2.58342C1.66675 1.91676 2.33341 1.50009 2.91675 1.75009L14.0834 7.33342Z" stroke="#E35C49" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.0834 12.6667L3.41675 18L11.4167 10L14.0834 12.6667Z" stroke="#E35C49" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M11.4167 10L3.41675 2" stroke="#E35C49" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </a>
            </li>
            
            <li class="bg-white rounded-full p-2 cursor-pointer footer__apps--item">
              <a href="https://www.youtube.com/channel/UC7zzJ0gaX5QrE8lPqG_Lr1w">
                <svg class="w-7 h-7 w-6 h-6" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.1667 16.6663H5.83341C3.33341 16.6663 1.66675 14.9997 1.66675 12.4997V7.49967C1.66675 4.99967 3.33341 3.33301 5.83341 3.33301H14.1667C16.6667 3.33301 18.3334 4.99967 18.3334 7.49967V12.4997C18.3334 14.9997 16.6667 16.6663 14.1667 16.6663Z" stroke="#E35C49" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.5 7.91676L11.5833 9.16676C12.3333 9.66676 12.3333 10.4168 11.5833 10.9168L9.5 12.1668C8.66667 12.6668 8 12.2501 8 11.3334V8.83342C8 7.75009 8.66667 7.41676 9.5 7.91676Z" stroke="#E35C49" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </a>
            </li>
          </ul>

          <div>
            <span class={`${textWhite ? 'text-white' : 'text-gray-800'} text-xs`}>© 2022 Snap Delivered. All rights reserved.</span>
          </div>
        </section>
      </div>
    </footer>
  )
}