import React, { useState, useRef } from 'react'
import gql from 'graphql-tag'
import { validateFunc } from '../constraints/constraints'
import { withTranslation } from 'react-i18next'
import Header from '../components/Headers/Header'
import { Query, Mutation } from 'react-apollo'
import { getRestaurantProfile, editRestaurant } from '../apollo/server'
import { CLOUDINARY_UPLOAD_URL, CLOUDINARY_FOOD } from '../config/config'
// reactstrap components
import {
  Button,
  Card,
  Alert,
  CardHeader,
  CardBody,
  Container,
  FormGroup,
  Input,
  Row,
  Col
} from 'reactstrap'
import { handleErrors } from '../utils/handleErrors'

const GET_PROFILE = gql`
  ${getRestaurantProfile}
`
const EDIT_RESTAURANT = gql`
  ${editRestaurant}
`

const VendorProfile = props => {
  const { t } = props
  const restaurantId = localStorage.getItem('restaurant_id')

  const [imgUrl, setImgUrl] = useState('')
  const [nameError, setNameError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [phoneSMSNotificationError, setPhoneSMSNotificationError] = useState(null)
  const [usernameError, setUsernameError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [addressError, setAddressError] = useState(null)
  const [latError, setLatError] = useState(null)
  const [longError, setLongError] = useState(null)
  const [prefixError, setPrefixError] = useState(null)
  const [deliveryChargesError, setDeliveryChargesError] = useState(null)
  const [deliveryTimeError, setDeliveryTimeError] = useState(null)
  const [minimumOrderError, setMinimumOrderError] = useState(null)
  const [salesTaxError, setSalesTaxError] = useState(null)
  const [errors, setErrors] = useState('')
  const [success, setSuccess] = useState('')

  const formRef = useRef(null)

  const selectImage = (event, state) => {
    const result = filterImage(event)
    if (result) imageToBase64(result)
  }

  const filterImage = event => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i)
    }
    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
    const message = `${images.length} valid image(s) selected`
    console.log(message)
    return images.length ? images[0] : undefined
  }
  const imageToBase64 = imgUrl => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      setImgUrl(fileReader.result)
    }
    fileReader.readAsDataURL(imgUrl)
  }
  const uploadImageToCloudinary = async () => {
    if (imgUrl === '') return imgUrl

    const apiUrl = CLOUDINARY_UPLOAD_URL
    const data = {
      file: imgUrl,
      upload_preset: CLOUDINARY_FOOD
    }
    try {
      const result = await fetch(apiUrl, {
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST'
      })
      const imageData = await result.json()
      return imageData.secure_url
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmitValidaiton = data => {
    const form = formRef.current
    const name = form.name.value
    const phone = form.phone.value
    const phoneSMSNotification = form.phoneSMSNotification.value
    const address = form.address.value
    const latitude = form.latitude.value
    const longitude = form.longitude.value
    const username = form.username.value
    const password = form.password.value

    // IMPORTANT!!!!
    const prefix = form.prefix.value
    const deliveryCharges = form.deliveryCharges.value
    const deliveryTime = form.deliveryTime.value
    const minimumOrder = form.minimumOrder.value
    const salesTax = +form.salesTax.value

    const nameErrors = !validateFunc({ name }, 'name')
    const phoneErrors = String(phone).length>0 ?  !validateFunc({ phone }, 'phone') : true
    const phoneSMSNotificationErrors = String(phoneSMSNotification).length>0 ?  !validateFunc({ phone: phoneSMSNotification }, 'phone') : true
    console.log(phoneSMSNotificationErrors)
    const addressErrors = !validateFunc({ address }, 'address')
    const latErrors = !validateFunc({ lat: latitude }, 'lat')
    const longErrors = !validateFunc({ long: longitude }, 'long')
    const prefixErrors = !validateFunc({ prefix: prefix }, 'prefix')
    const deliveryChargesErrors = !validateFunc(
      { deliveryCharges: deliveryCharges },
      'deliveryCharges'
    )
    const deliveryTimeErrors = !validateFunc(
      { deliveryTime: deliveryTime },
      'deliveryTime'
    )
    const minimumOrderErrors = !validateFunc(
      { minimumOrder: minimumOrder },
      'minimumOrder'
    )
    const usernameErrors = !validateFunc({ name: username }, 'name')
    const passwordErrors = String(password).length>0 ? !validateFunc({ password }, 'password') : true
    const salesTaxError = !validateFunc({ salesTax }, 'salesTax')
    setNameError(nameErrors)
    setPhoneError(phoneErrors)
    setPhoneSMSNotificationError(phoneSMSNotificationErrors)
    setAddressError(addressErrors)
    setLatError(latErrors)
    setLongError(longErrors)
    setPrefixError(prefixErrors)
    setDeliveryChargesError(deliveryChargesErrors)
    setUsernameError(usernameErrors)
    setPasswordError(passwordErrors)
    setDeliveryTimeError(deliveryTimeErrors)
    setMinimumOrderError(minimumOrderErrors)
    setSalesTaxError(salesTaxError)

    if (
      !(
        nameErrors &&
        phoneErrors &&
        phoneSMSNotificationErrors &&
        addressErrors &&
        latErrors &&
        longErrors &&
        deliveryChargesErrors &&
        prefixErrors &&
        usernameErrors &&
        passwordErrors &&
        deliveryTimeErrors &&
        minimumOrderErrors &&
        salesTaxError
      )
    ) {
      setErrors('Fields Required')
    }
    return (
      nameErrors &&
      phoneErrors &&
      phoneSMSNotificationErrors &&
      addressErrors &&
      latErrors &&
      longErrors &&
      deliveryChargesErrors &&
      prefixErrors &&
      usernameErrors &&
      passwordErrors &&
      deliveryTimeErrors &&
      minimumOrderErrors &&
      salesTaxError
    )
  }
  const onCompleted = data => {
    setNameError(null)
    setPhoneError(null)
    setPhoneSMSNotificationError(null)
    setAddressError(null)
    setLatError(null)
    setLongError(null)
    setDeliveryChargesError(null)
    setPrefixError(null)
    setUsernameError(null)
    setPasswordError(null)
    setDeliveryTimeError(null)
    setMinimumOrderError(null)
    setSalesTaxError(null)
    setErrors('')
    setSuccess('Restaurant updated successfully')
  }

  const onError = ({ graphQLErrors, networkError }) => {
    setNameError(null)
    setPhoneError(null)
    setPhoneSMSNotificationError(null)
    setAddressError(null)
    setLatError(null)
    setLongError(null)
    setDeliveryChargesError(null)
    setPrefixError(null)
    setUsernameError(null)
    setPasswordError(null)
    setDeliveryTimeError(null)
    setMinimumOrderError(null)
    setSalesTaxError(null)
    setSuccess('')
    if (graphQLErrors) {
      setErrors(graphQLErrors[0].message)
    }
    if (networkError) {
      setErrors(networkError.result.errors[0].message)
    }
  }
  const onDismiss = () => {
    setNameError(null)
    setPhoneError(null)
    setPhoneSMSNotificationError(null)
    setAddressError(null)
    setLatError(null)
    setLongError(null)
    setDeliveryChargesError(null)
    setUsernameError(null)
    setPasswordError(null)
    setDeliveryTimeError(null)
    setMinimumOrderError(null)
    setSalesTaxError(null)
    setSuccess('')
    setErrors('')
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{t('Update Profile')}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Query
                  query={GET_PROFILE}
                  variables={{ id: restaurantId }}
                  onError={error => {
                    console.log(error)
                  }}>
                  {({ loading, error, data }) => {
                    if (loading) return <span>{t('Loading...')}</span>
                    if (error) {
                      const errorMsg = handleErrors(error.graphQLErrors)
                      return <span>{errorMsg}</span>
                    }

                    return (
                      <>
                        <form ref={formRef}>
                          <Row>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-username">
                                Restaurant Email
                              </label>
                              <FormGroup
                                className={
                                  usernameError === false
                                    ? 'has-danger'
                                    : usernameError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="username"
                                  className="form-control-alternative"
                                  id="input-type-username"
                                  placeholder="e.g Test"
                                  type="text"
                                  defaultValue={data.restaurant.username || ''}
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-password">
                                {t("Restaurant's Password")}
                              </label>
                              <FormGroup
                                className={
                                  passwordError === false
                                    ? 'has-danger'
                                    : passwordError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="password"
                                  className="form-control-alternative"
                                  id="input-type-password"
                                  placeholder="e.g 123456"
                                  type="text"
                                  defaultValue={''}
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-name">
                                {t("Restaurant's Name")}
                              </label>
                              <br />
                              <FormGroup
                                className={
                                  nameError === false
                                    ? 'has-danger'
                                    : nameError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="name"
                                  className="form-control-alternative"
                                  id="input-type-name"
                                  placeholder="e.g Johns Food"
                                  type="text"
                                  defaultValue={data.restaurant.name || ''}
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-address">
                                {t("Restaurant's address")}
                              </label>
                              <br />
                              <FormGroup
                                className={
                                  addressError === false
                                    ? 'has-danger'
                                    : addressError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="address"
                                  className="form-control-alternative"
                                  id="input-type-address"
                                  placeholder="e.g Abc Street, xyz city, country"
                                  type="text"
                                  defaultValue={data.restaurant.address}
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <label
                                  className="form-control-label"
                                  htmlFor="input-type-name">
                                Restaurant's Public Phone Number
                              </label>
                              <br />
                              <FormGroup
                                  className={
                                    phoneError === false
                                        ? 'has-danger'
                                        : phoneError === true
                                            ? 'has-success'
                                            : ''
                                  }>
                                <Input
                                    name="phone"
                                    className="form-control-alternative"
                                    id="input-type-name"
                                    placeholder="e.g +1456789456"
                                    type="text"
                                    defaultValue={data.restaurant.phone || ''}
                                    autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <label
                                  className="form-control-label"
                                  htmlFor="input-type-name">
                                Restaurant's SMS Notification Phone Number
                              </label>
                              <br />
                              <FormGroup
                                  className={
                                    phoneSMSNotificationError === false
                                        ? 'has-danger'
                                        : phoneSMSNotificationError === true
                                            ? 'has-success'
                                            : ''
                                  }>
                                <Input
                                    name="phoneSMSNotification"
                                    className="form-control-alternative"
                                    id="input-type-name"
                                    placeholder="e.g +1456789456"
                                    type="text"
                                    defaultValue={data.restaurant.phoneSMSNotification || ''}
                                    autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-lat">
                                {t('Latitude')}
                              </label>
                              <br />
                              <FormGroup
                                className={
                                  latError === false
                                    ? 'has-danger'
                                    : latError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="latitude"
                                  className="form-control-alternative"
                                  id="input-type-lat"
                                  placeholder="e.g 77.34"
                                  type="text"
                                  defaultValue={
                                    data.restaurant.location
                                      ? data.restaurant.location
                                          .coordinates[1] || ''
                                      : ''
                                  }
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-long">
                                {t('Longitude')}
                              </label>
                              <br />
                              <FormGroup
                                className={
                                  longError === false
                                    ? 'has-danger'
                                    : longError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="longitude"
                                  className="form-control-alternative"
                                  id="input-type-long"
                                  placeholder="e.g -33.45"
                                  type="text"
                                  defaultValue={
                                    data.restaurant.location
                                      ? data.restaurant.location
                                          .coordinates[0] || ''
                                      : ''
                                  }
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-order_id_prefix">
                                {t('Order Prefix')}
                              </label>
                              <br />
                              <FormGroup
                                className={
                                  prefixError === false
                                    ? 'has-danger'
                                    : prefixError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="prefix"
                                  className="form-control-alternative"
                                  id="input-type-order_id_prefix"
                                  placeholder="e.g -33.45"
                                  type="text"
                                  defaultValue={data.restaurant.orderPrefix}
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-delivery_charges">
                                {t('Delivery Charges')}
                              </label>
                              <br />
                              <FormGroup
                                className={
                                  deliveryChargesError === false
                                    ? 'has-danger'
                                    : deliveryChargesError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="deliveryCharges"
                                  className="form-control-alternative"
                                  id="input-type-delivery_charges"
                                  placeholder="e.g 33.45"
                                  type="text"
                                  defaultValue={data.restaurant.deliveryCharges}
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-minimum-order">
                                {t('Minimum Order')}
                              </label>
                              <br />
                              <FormGroup
                                className={
                                  minimumOrderError === false
                                    ? 'has-danger'
                                    : minimumOrderError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="minimumOrder"
                                  className="form-control-alternative"
                                  id="input-type-minimum-order"
                                  placeholder="e.g 200"
                                  type="text"
                                  defaultValue={data.restaurant.minimumOrder}
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-delivery-time">
                                {t('Delivery Time')}
                              </label>
                              <br />
                              <FormGroup
                                className={
                                  deliveryTimeError === false
                                    ? 'has-danger'
                                    : deliveryTimeError === true
                                    ? 'has-success'
                                    : ''
                                }>
                                <Input
                                  name="deliveryTime"
                                  className="form-control-alternative"
                                  id="input-type-delivery-time"
                                  placeholder="e.g 33.45"
                                  type="text"
                                  defaultValue={data.restaurant.deliveryTime}
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <label
                                  className="form-control-label"
                                  htmlFor="input-type-sales-tax">
                                {t('Sales Tax %')}
                              </label>
                              <br />
                              <FormGroup
                                  className={
                                    salesTaxError === false
                                        ? 'has-danger'
                                        : salesTaxError === true
                                            ? 'has-success'
                                            : ''
                                  }>
                                <Input
                                    name="salesTax"
                                    className="form-control-alternative"
                                    id="input-type-sales-tax"
                                    placeholder="e.g 0-100"
                                    type="number"
                                    defaultValue={data && data.restaurant.tax}
                                    autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-type-image">
                                {t('Image')}
                                <span>
                                  : Recommended Resolution: 900 x 400 px (Centered Logo and leave background on the top,bottom and side of logo)
                                </span>
                              </label>
                              <br />
                              <FormGroup>
                                <div className="card-title-image">
                                  <a
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="rounded-rectangle"
                                      src={imgUrl || data.restaurant.image}
                                    />
                                  </a>
                                </div>
                                <input
                                  className="mt-2"
                                  type="file"
                                  onChange={event => {
                                    selectImage(event, 'image_url')
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr />
                          <Mutation
                            mutation={EDIT_RESTAURANT}
                            onError={error => onError(error)}
                            onCompleted={onCompleted}>
                            {(mutate, { loading: mutationLoading, error }) => {
                              return (
                                <Row className="mt-2 justify-content-center">
                                  <Col xs="4">
                                    <Button
                                      color="primary"
                                      href="#pablo"
                                      className="btn-block"
                                      onClick={async e => {
                                        e.preventDefault()
                                        if (onSubmitValidaiton()) {
                                          const imgUpload = await uploadImageToCloudinary()
                                          const form = formRef.current
                                          const name = form.name.value
                                          const phone = form.phone.value
                                          const phoneSMSNotification = form.phoneSMSNotification.value
                                          const address = form.address.value
                                          const latitude = form.latitude.value
                                          const longitude = form.longitude.value
                                          const prefix = form.prefix.value // can we not update this?
                                          const salesTax = form.salesTax.value
                                          const deliveryCharges =
                                            form.deliveryCharges.value
                                          const deliveryTime =
                                            form.deliveryTime.value
                                          const minimumOrder =
                                            form.minimumOrder.value
                                          const username = form.username.value
                                          const password = form.password.value
                                          mutate({
                                            variables: {
                                              restaurantInput: {
                                                _id: restaurantId,
                                                name,
                                                phone,
                                                phoneSMSNotification,
                                                address,
                                                image:
                                                  imgUpload ||
                                                  data.restaurant.image ||
                                                  'https://www.lifcobooks.com/wp-content/themes/shopchild/images/placeholder_book.png',
                                                lat: latitude,
                                                long: longitude,
                                                orderPrefix: prefix,
                                                deliveryCharges: Number(
                                                  deliveryCharges
                                                ),
                                                deliveryTime: Number(
                                                  deliveryTime
                                                ),
                                                minimumOrder: Number(
                                                  minimumOrder
                                                ),
                                                username: username,
                                                password: password,
                                                salesTax: +salesTax
                                              }
                                            }
                                          })
                                        }
                                      }}
                                      size="lg">
                                      {mutationLoading
                                        ? t('Updating...')
                                        : t('Update')}
                                    </Button>
                                  </Col>
                                </Row>
                              )
                            }}
                          </Mutation>
                          <Row>
                            <Col lg="6">
                              <Alert
                                color="success"
                                isOpen={!!success}
                                toggle={onDismiss}>
                                <span className="alert-inner--icon">
                                  <i className="ni ni-like-2" />
                                </span>{' '}
                                <span className="alert-inner--text">
                                  <strong>{t('Success')}!</strong> {success}
                                </span>
                              </Alert>
                              <Alert
                                color="danger"
                                isOpen={!!errors}
                                toggle={onDismiss}>
                                <span className="alert-inner--icon">
                                  <i className="ni ni-like-2" />
                                </span>{' '}
                                <span className="alert-inner--text">
                                  <strong>{t('Danger')}!</strong> {errors}
                                </span>
                              </Alert>
                            </Col>
                          </Row>
                        </form>
                      </>
                    )
                  }}
                </Query>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default withTranslation()(VendorProfile)
