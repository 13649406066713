import React from 'react'
import AdminLayout from './layouts/Admin.jsx'
import RestaurantLayout from './layouts/Restaurant.jsx'
import AuthLayout from './layouts/Auth.jsx'
import SuperAdminLayout from './layouts/SuperAdmin.jsx'
import { PrivateRoute } from './views/PrivateRoute'
import { AdminPrivateRoute } from './views/AdminPrivateRoute'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
require('./i18n')

const App = () => {
  const userType = localStorage.getItem('user-snap')
    ? JSON.parse(localStorage.getItem('user-snap')).userType
    : null
  const route = userType
    ? userType === 'VENDOR'
      ? '/restaurant/list'
      : '/super_admin/dispatch'
    : '/auth/login'
  return (
    <BrowserRouter>
      <Switch>
        <AdminPrivateRoute
          path="/super_admin"
          component={props => <SuperAdminLayout {...props} />}
        />
        <PrivateRoute
          path="/restaurant"
          component={props => <RestaurantLayout {...props} />}
        />
        <PrivateRoute
          path="/admin"
          component={props => <AdminLayout {...props} />}
        />
        <Route path="/auth" component={props => <AuthLayout {...props} />} />
        <Redirect from="/" to={route} />
      </Switch>
    </BrowserRouter>
  )
}
export default App
