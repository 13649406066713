/* eslint-disable react/display-name */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Query, Mutation, compose, withApollo } from 'react-apollo'
import { withTranslation } from 'react-i18next'
// reactstrap components
import { Badge, Card, Container, Row, Media, Modal } from 'reactstrap'
// core components
import Header from '../components/Headers/Header'
import { getRestaurantDetail, deleteFood } from '../apollo/server'
import FoodComponent from '../components/Food/Food'
import CustomLoader from '../components/Loader/CustomLoader'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { transformToNewline } from '../utils/stringManipulations'
import Loader from 'react-loader-spinner'
import { handleErrors } from '../utils/handleErrors'

const GET_FOODS = gql`
  ${getRestaurantDetail}
`
const DELETE_FOOD = gql`
  ${deleteFood}
`

const Food = props => {
  const [editModal, setEditModal] = useState(false)
  const [food, setFood] = useState(null)

  const toggleModal = food => {
    setEditModal(!editModal)
    setFood(food)
  }

  const propExists = (obj, path) => {
    return path.split('.').reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : ''
    }, obj)
  }

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (field && isNaN(propExists(row, field))) {
        return propExists(row, field).toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const restaurantId = localStorage.getItem('restaurant_id')
  const columns = [
    {
      name: 'Title',
      selector: 'title',
      sortable: true,
      cell: row => (
        <>
          <Media>
            <span className="mb-0 text-sm">{row.title}</span>
          </Media>
        </>
      )
    },
    {
      name: 'Description',
      sortable: true,
      selector: 'description',
      cell: row => <>{transformToNewline(row.description, 3)}</>
    },
    {
      name: 'Category',
      sortable: true,
      selector: 'category.category',
      cell: row => <>{row.category}</>
    },
    {
      name: 'Image',
      cell: row => (
        <>
          {!!row.image && (
            <img className="img-responsive" src={row.image} alt="img menu" />
          )}
          {!row.image && 'No Image'}
        </>
      )
    },
    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>
    }
  ]

  const actionButtons = row => {
    return (
      <>
        <Badge
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          Edit
        </Badge>
        &nbsp;&nbsp;
        <Mutation
          mutation={DELETE_FOOD}
          refetchQueries={[
            { query: GET_FOODS, variables: { id: restaurantId } }
          ]}>
          {(deleteFood, { loading: deleteLoading }) => {
            if (deleteLoading) {
              return (
                <Loader
                  type="ThreeDots"
                  color="#BB2124"
                  height={20}
                  width={40}
                  visible={deleteLoading}
                />
              )
            }
            return (
              <Badge
                href="#pablo"
                color="danger"
                onClick={e => {
                  e.preventDefault()
                  deleteFood({
                    variables: {
                      id: row._id,
                      restaurant: restaurantId,
                      categoryId: row.categoryId
                    }
                  })
                }}>
                {'Delete'}
              </Badge>
            )
          }}
        </Mutation>
      </>
    )
  }

  const foodsList = categories => {
    const list = []
    categories.forEach(category => {
      if (category.foods && category.foods.length) {
        return category.foods.map(item => {
          list.push({
            ...item,
            category: category.title,
            categoryId: category._id,
            ...category,
            _id: item._id,
            title: item.title
          })

          return {
            ...item,
            category: category.title,
            categoryId: category._id,
            ...category,
            _id: item._id,
            title: item.title
          }
        })
      }
    })
    return list
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <FoodComponent />
        {/* Table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <Query query={GET_FOODS} variables={{ id: restaurantId }}>
                {({ loading, error, data }) => {
                  if (error) {
                    const errorMsg = handleErrors(error.graphQLErrors)
                    return (
                      <span>
                        {'Error'}! {errorMsg}
                      </span>
                    )
                  }
                  return (
                    <DataTable
                      title={'Foods'}
                      columns={columns}
                      data={
                        data.restaurant
                          ? foodsList(data.restaurant.categories)
                          : {}
                      }
                      pagination
                      progressPending={loading}
                      progressComponent={<CustomLoader />}
                      onSort={handleSort}
                      sortFunction={customSort}
                      defaultSortField="title"
                    />
                  )
                }}
              </Query>
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal()
          }}>
          <FoodComponent food={food} />
        </Modal>
      </Container>
    </>
  )
}

export default compose(withApollo, withTranslation())(Food)
