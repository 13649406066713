import React, {useRef, useState} from 'react'
import { withTranslation } from 'react-i18next'
import {Container, Row, Card, FormGroup, Input, Button} from 'reactstrap'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { Query, compose, withApollo, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { getUsers, updateDeliveryDate } from '../apollo/server'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { handleErrors } from '../utils/handleErrors'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const GET_USERS = gql`
  ${getUsers}
`

const UPDATE_DELIVERY_DATE = gql`
  ${updateDeliveryDate}
`

let refreshGlobal

const Users = props => {
  const searchRef = useRef(null)
  const [filter, setFilter] = useState('')
  const columns = [
    {
      name: 'Name',
      sortable: true,
      selector: 'name'
    },
    {
      name: 'Email',
      sortable: true,
      selector: 'email'
    },
    {
      name: 'Phone',
      sortable: true,
      selector: 'phone'
    },
    {
      name: 'Free Delivery Until',
      cell: row => {

        const dateinTimestamp = parseInt(row.subscribedExpiredDate)

        console.log(row)

        if(!dateinTimestamp>0) return <></>
        if(!row.subscribed) return <></>

        const date = new Date(dateinTimestamp);
        const formattedDate= ('0' + (date.getMonth()+1)).slice(-2)+"/"+('0' + date.getDate()).slice(-2)+"/"+date.getFullYear()
        return <>
          {formattedDate}
        </>
      }
    },
    {
      name: 'Change Free Delivery Date',
      cell: row => {
        let defaultDate = new Date()
        const dateinTimestamp = parseInt(row.subscribedExpiredDate)
        if(dateinTimestamp>0){
          defaultDate = new Date(dateinTimestamp);
        }
        const [startDate, setStartDate] = useState(defaultDate);
        return <>
          <Mutation mutation={UPDATE_DELIVERY_DATE} onCompleted={() => {refreshGlobal()}}>
            {updateDeliveryDate => {
              return (
                  <>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}/>
                    <Button size={"sm"} color="primary" onClick={() => {updateDeliveryDate({variables: {newDate:startDate, userId: row._id}})}}>Update</Button>
                  </>
            )
            }}
          </Mutation>
          </>
      }
    }
  ]

  /*
  const hiddenData = (cell, column) => {
    if (column === 'EMAIL') {
      if (cell != null) {
        const splitArray = cell.split('@')
        splitArray.splice(0, 1, '*'.repeat(splitArray[0].length))
        const star = splitArray.join('@')
        return star
      } else {
        return '*'
      }
    } else if (column === 'PHONE') {
      const star = '*'.repeat(cell.length)
      return star
    }
  }

   */
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const { t } = props

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <Query
                query={GET_USERS}
                variables={{ page: 0, filter }}
                onError={error => {
                  console.log(error)
                }}>
                {({ loading, error, data, refetch }) => {
                  refreshGlobal = refetch
                  if (error) {
                    const errorMsg = handleErrors(error.graphQLErrors)
                    return (
                      <span>
                        {t('Error')}! {errorMsg}
                      </span>
                    )
                  }
                  return (
                      <>
                        <FormGroup className=''>
                          <div className="col">
                            <label
                                className="form-control-label"
                                htmlFor="input-type-username">
                              Filter Users
                            </label>
                            <Input
                                innerRef={searchRef}
                                className="form-control-alternative"
                                id="input-description"
                                placeholder="Type name or email"
                                type="text"
                            />
                            <Button
                                className={"mt-3"}
                                disabled={loading}
                                onClick={() =>{
                                  setFilter(searchRef.current.value)
                                }}
                                color="primary"
                            >Search</Button>
                          </div>
                        </FormGroup>
                        <DataTable
                            title={t('Users')}
                            columns={columns}
                            data={data.users}
                            pagination
                            progressPending={loading}
                            progressComponent={<CustomLoader />}
                            onSort={handleSort}
                            sortFunction={customSort}
                        />
                      </>
                  )
                }}
              </Query>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default compose(withApollo, withTranslation())(Users)
