import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import gql from 'graphql-tag'
import { getMainDefinition } from 'apollo-utilities'
import { ApolloLink, split, concat, Observable } from 'apollo-link'
import { LoadScript } from '@react-google-maps/api'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache } from 'apollo-cache-inmemory'
import * as firebase from 'firebase/app'
import 'firebase/messaging'
import './assets/vendor/nucleo/css/nucleo.css'
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import './assets/scss/argon-dashboard-react.scss'
import { getOrders, uploadToken } from '../src/apollo/server'

import { WS_SERVER_URL, SERVER_URL } from './config/config'
import App from './app'
const GET_ORDERS = gql`
  ${getOrders}
`
const UPLOAD_TOKEN = gql`
  ${uploadToken}
`

const firebaseConfig = {
    apiKey: "AIzaSyC8nRTWlPnKlI-3rMjkB36gdtojBUbxFWo",
    authDomain: "snapdelivered-1619b.firebaseapp.com",
    projectId: "snapdelivered-1619b",
    storageBucket: "snapdelivered-1619b.appspot.com",
    messagingSenderId: "421998501271",
    appId: "1:421998501271:web:93013c0b078ce6dc1df8d2",
    measurementId: "G-VZXJFKG45M"
};

const cache = new InMemoryCache()
const httpLink = createHttpLink({
  uri: `${SERVER_URL}graphql`
})
const wsLink = new WebSocketLink({
  uri: `${WS_SERVER_URL}graphql`,
  options: {
    reconnect: true
  }
})
const request = async operation => {
  const data = localStorage.getItem('user-snap')

  let token = null
  if (data) {
    token = JSON.parse(data).token
  }
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  })
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)
const terminatingLink = split(({ query }) => {
  const { kind, operation } = getMainDefinition(query)
  return kind === 'OperationDefinition' && operation === 'subscription'
}, wsLink)
const client = new ApolloClient({
  link: concat(ApolloLink.from([terminatingLink, requestLink]), httpLink),
  cache
})

// // Initialize Firebase
firebase.initializeApp(firebaseConfig)
if (firebase.messaging.isSupported()) {

  const messaging = firebase.messaging()
  messaging.usePublicVapidKey(
    'BGLnwBnX8e2jZ_cCtexw1jCQUqXTW1cv-Pp5X9gzAKJirWUviyRG4PltuZzprx3gbcA2gPYGgUUXT4zvSqDzRc8'
  )
  messaging
    .requestPermission()
    .then(function () {
      messaging
        .getToken()
        .then(function (currentToken) {
          const data = localStorage.getItem('user-snap')

          if (currentToken && data) {
            const id = JSON.parse(data).userId.toString()
            client
              .mutate({
                mutation: UPLOAD_TOKEN,
                variables: { id, pushToken: currentToken }
              })
              .then(() => { })
              .catch(() => { })
          } else {
          }
        })
        .catch(function () { })
    })
    .catch(function () { })

  messaging.onMessage(function (payload) {
    console.log('Notification Payload', payload)
    var notificationTitle = 'New Order on Snap Delivery'
    var notificationOptions = {
      body: payload.data.orderid
      // icon: 'https://multivendor.ninjascode.com/assets/images/logo.png'
    }
    const nt = new Notification(notificationTitle, notificationOptions)
    nt.onclick = function (event) {
      event.preventDefault() // prevent the browser from focusing the Notification's tab
      // window.open('https://multivendor.ninjascode.com/dashboard')
      nt.close()
    }

    client.query({ query: GET_ORDERS, fetchPolicy: 'network-only' })
  })
}
ReactDOM.render(
  <ApolloProvider client={client}>
    <LoadScript
      libraries={['places']}
      id="script-loader"
      googleMapsApiKey="AIzaSyAq8L4mpVsRU0XYfdm4njQJ_Ntpi-KOvyc">
      <App />
    </LoadScript>
  </ApolloProvider>,
  document.getElementById('root')
)
