/* eslint-disable react/display-name */
import React, {useState, useRef } from 'react'
import { SERVER_URL } from '../config/config'
import {withTranslation} from 'react-i18next'
// reactstrap components
import {Alert, Container} from 'reactstrap'
import axios from 'axios'
import {
    Button,
    Card,
    CardHeader,
    FormGroup,
    Row,
    Col
} from 'reactstrap'
// core components
import Header from '../components/Headers/Header'
import Loader from "react-loader-spinner";

function Uploader() {

    const [selectedFile, setSelectedFile] = useState(null)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [submiting, setSubmiting] = useState(false)
    const refMenu = useRef();

    const submit = async () =>{
        setSuccess(false)
        setError('')
        setSubmiting(true)
        const formData = new FormData();
        formData.append("menu", selectedFile);
        try {
            await axios({
                method: "post",
                url: `${SERVER_URL}admin/uploadMenu`,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user-snap")).token}`
                },
            });
            setSuccess(true)
        } catch(error) {
            setError(error.response.data.error_message)
        }
        refMenu.current.value = "";
        setSubmiting(false)
    }

    return (
        <>
            <Header/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col>
                                        <h3 className="mb-0">
                                            Upload Menu
                                        </h3>
                                        <FormGroup>
                                            <div className="card-title-image">
                                                <input
                                                    className="mt-4"
                                                    type="file"
                                                    ref={refMenu}
                                                    onChange={event => {
                                                        setSelectedFile(event.target.files[0])
                                                    }}
                                                />
                                                <Button disabled={submiting || selectedFile === null} color="primary" className="ml-4" onClick={() =>{submit()}}>
                                                    {!submiting && (
                                                    <span>Upload</span>
                                                    )}
                                                    {submiting && (
                                                    <Loader type="TailSpin" color="#ffffff" height={20} width={20} />
                                                        )}
                                                </Button>
                                            </div>

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row className="align-items-center">
                                    <Col>
                                        <h3 className="mb-0">
                                            Upload Restaurants
                                        </h3>
                                        <FormGroup>
                                            <div className="card-title-image">
                                                <input
                                                    className="mt-4"
                                                    type="file"
                                                />
                                                <Button disabled={submiting || selectedFile === null} color="primary" className="ml-4" onClick={() =>{submit()}}>
                                                    {!submiting && (
                                                        <span>Upload</span>
                                                    )}
                                                    {submiting && (
                                                        <Loader type="TailSpin" color="#ffffff" height={20} width={20} />
                                                    )}
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {error && (
                                    <Alert color="danger">
                                        <span className="alert-inner--icon"><i className="ni ni-like-2"/>
                                        </span>{' '}<span className="alert-inner--text">
                                          <strong>Error!</strong> {error}
                                        </span>
                                    </Alert>
                                )}

                                {success && (
                                    <Alert color="success">
                                        <span className="alert-inner--icon"><i className="ni ni-like-2"/>
                                        </span>{' '}<span className="alert-inner--text">
                                          <strong>File Uploaded Successfully</strong>
                                        </span>
                                    </Alert>
                                )}
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default withTranslation()(Uploader)
