import React from 'react'

function Header() {
  return (
    <>
      <div className="header header__bgGradient pb-8 pt-5 pt-md-8 asd"></div>
    </>
  )
}

export default Header
