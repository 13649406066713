/* eslint-disable react/display-name */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Query, Mutation, compose, withApollo } from 'react-apollo'
import { withTranslation } from 'react-i18next'
import CategoryComponent from '../components/Category/Category'
import CustomLoader from '../components/Loader/CustomLoader'
// reactstrap components
import { Badge, Card, Container, Row, Modal } from 'reactstrap'
// core components
import Header from '../components/Headers/Header'
import {
  getRestaurantDetail,
  deleteCategory,
  editCategoryTypeAdmin
} from '../apollo/server'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import Loader from 'react-loader-spinner'
import { handleErrors } from '../utils/handleErrors'

const GET_CATEGORIES = gql`
  ${getRestaurantDetail}
`
const DELETE_CATEGORY = gql`
  ${deleteCategory}
`

const EDIT_CATEGORY_TYPE_ADMIN = gql`
  ${editCategoryTypeAdmin}
`

const CategoryTimed = props => {
  const [editModal, setEditModal] = useState(false)
  const [category, setCategory] = useState(null)

  const toggleModal = category => {
    setEditModal(!editModal)
    setCategory(category)
  }
  const restaurantId = localStorage.getItem('restaurant_id')

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const columns = [
    {
      name: 'Title',
      sortable: true,
      selector: 'title'
    },
    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>
    }
  ]
  const actionButtons = row => {
    return (
      <>
        <Badge
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          Edit
        </Badge>
        &nbsp;&nbsp;
        <Mutation
          mutation={DELETE_CATEGORY}
          refetchQueries={[
            {
              query: GET_CATEGORIES,
              variables: { id: row._id, restaurant: restaurantId }
            }
          ]}>
          {(deleteCategory, { loading: deleteLoading }) => {
            if (deleteLoading) {
              return (
                <Loader
                  type="ThreeDots"
                  color="#BB2124"
                  height={20}
                  width={40}
                  visible={deleteLoading}
                />
              )
            }
            return (
              <Badge
                href="#pablo"
                color="danger"
                onClick={e => {
                  e.preventDefault()
                  deleteCategory({
                    variables: { id: row._id, restaurant: restaurantId }
                  })
                }}>
                {'Delete'}
              </Badge>
            )
          }}
        </Mutation>
      </>
    )
  }
  const { t } = props

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Query query={GET_CATEGORIES} variables={{ id: restaurantId }}>
          {({ loading, error, data }) => {
            const restaurant = data ? data.restaurant : null
            return (
              <>
                <Mutation
                  mutation={EDIT_CATEGORY_TYPE_ADMIN}
                  refetchQueries={[
                    {
                      query: GET_CATEGORIES,
                      variables: { id: restaurantId }
                    }
                  ]}>
                  {_editCategoryTypeAdmin => {
                    return (
                      <div className="mt-5">
                        <div className="col">
                          <Row
                            md="8"
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <label className="mr-2">Use Timed Categories</label>
                            <di>
                              <label className="custom-toggle">
                                <input
                                  checked={
                                    restaurant
                                      ? restaurant.usedCategory === 'TIMED'
                                      : false
                                  }
                                  type="checkbox"
                                  onChange={event => {
                                    const nextState =
                                      Boolean(restaurant) &&
                                      data.restaurant.usedCategory === 'TIMED'
                                        ? 'DEFAULT'
                                        : 'TIMED'
                                    _editCategoryTypeAdmin({
                                      variables: { restaurantId: restaurantId, type: nextState }
                                    })
                                  }}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </di>
                          </Row>
                        </div>
                      </div>
                    )
                  }}
                </Mutation>
                <CategoryComponent type={'TIMED'} />
                <Row className="mt-5">
                  <div className="col">
                    <Card className="shadow">
                      {error ? (
                        <span>
                          {t('Error')}! {handleErrors(error.graphQLErrors)}
                        </span>
                      ) : (
                        <DataTable
                          title={'Timed Categories'}
                          columns={columns}
                          data={
                            (data.restaurant && data.restaurant.categories)
                              ? data.restaurant.categories.filter(c => c.type === "TIMED")
                              : []
                          }
                          pagination
                          progressPending={loading}
                          progressComponent={<CustomLoader />}
                          onSort={handleSort}
                          sortFunction={customSort}
                          defaultSortField="title"
                        />
                      )}
                    </Card>
                  </div>
                </Row>
              </>
            )
          }}
        </Query>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal(null)
          }}>
          <CategoryComponent category={category} type={'TIMED'} />
        </Modal>
      </Container>
    </>
  )
}
export default compose(withApollo, withTranslation())(CategoryTimed)
