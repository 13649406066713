/*eslint-disable*/
import React from 'react'
import { withTranslation } from 'react-i18next'
// reactstrap components
// import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap'
import { FooterSnap } from './FooterSnap'

function AuthFooter(props) {
  // const { t } = props
  return (
    <>
      <div style={{ maxWidth: '1280px', margin: 'auto' }}>
        <FooterSnap textWhite />
      </div>

      {/* <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {t('2020')}{' '}
                <a
                  className="font-weight-bold ml-1"
                  // href="https://multivendor.ninjascode.com/"
                  target="_blank">
                  Snap Delivery
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink
                    // href="https://multivendor.ninjascode.com/"
                    target="_blank">
                    Snap Delivery
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    // href="https://ninjascode.com/pages/ourteam.html"
                    target="_blank">
                    {t('About Us')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    // href="https://medium.com/@sharangohar"
                    target="_blank">
                    {t('Blog')}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer> */}
    </>
  )
}

export default withTranslation()(AuthFooter)
