import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container } from 'reactstrap'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import Header from '../components/Headers/Header'
import { getConfiguration } from '../apollo/server'
import EmailConfiguration from '../components/Configuration/Email/Email'
import PaypalConfiguration from '../components/Configuration/Paypal/Paypal'
import StripeConfiguration from '../components/Configuration/Stripe/Stripe'
import CurrencyConfiguration from '../components/Configuration/Currency/Currency'
import { handleErrors } from '../utils/handleErrors'

const GET_CONFIGURATION = gql`
  ${getConfiguration}
`

const Configuration = props => {
  const { t } = props
  return (
    <>
      <Header />
      <Query query={GET_CONFIGURATION}>
        {({ loading, error, data }) => {
          if (loading) return t('Loading')
          if (error) {
            const errorMsg = handleErrors(error.graphQLErrors)
            return errorMsg
          }
          return (
            <Container className="mt--7" fluid>
              <EmailConfiguration
                email={data.configuration.email}
                password={data.configuration.password}
                enabled={data.configuration.enableEmail}
              />
              <PaypalConfiguration
                clientId={data.configuration.clientId}
                clientSecret={data.configuration.clientSecret}
                sandbox={data.configuration.sandbox}
              />
              <StripeConfiguration
                publishableKey={data.configuration.publishableKey}
                secretKey={data.configuration.secretKey}
              />
              <CurrencyConfiguration
                currencyCode={data.configuration.currency}
                currencySymbol={data.configuration.currencySymbol}
              />
            </Container>
          )
        }}
      </Query>
    </>
  )
}

export default withTranslation()(Configuration)
