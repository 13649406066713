const defaultTimings = [
  {
    day: 'MON',
    times: []
  },
  {
    day: 'TUE',
    times: []
  },
  {
    day: 'WED',
    times: []
  },
  {
    day: 'THU',
    times: []
  },
  {
    day: 'FRI',
    times: []
  },
  {
    day: 'SAT',
    times: []
  },
  {
    day: 'SUN',
    times: []
  }
]
const toServerReadableTiming = value => {
  const openingTimes = Object.keys(value).map(v => {
    return {
      day: v,
      times: value[v].map(timings => ({
        startTime: timings[0].split(':'),
        endTime: timings[1].split(':')
      }))
    }
  })
  return openingTimes
}

const fromServerReadableTiming = timings => {
  const transformedTimes = {}
  timings.forEach(value => {
    transformedTimes[value.day] = value.times.map(t => {
      if (isNaN(t.startTime[0]) || isNaN(t.startTime[1])) return []
      if (isNaN(t.endTime[0]) || isNaN(t.endTime[1])) return []
      return [
        `${t.startTime[0]}:${t.startTime[1]}`,
        `${t.endTime[0]}:${t.endTime[1]}`
      ]
    })
  })
  return transformedTimes
}

const TimingUtils = { toServerReadableTiming, fromServerReadableTiming, defaultTimings }

export default TimingUtils
