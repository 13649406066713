/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  Container,
  Row,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Button
} from 'reactstrap'
import {
  Query,
  compose,
  withApollo,
  Subscription,
  Mutation
} from 'react-apollo'
import gql from 'graphql-tag'
import DataTable from 'react-data-table-component'
import { GoogleMap, Marker } from '@react-google-maps/api'
import {
  getActiveOrders,
  getRidersByZone,
  subscriptionOrder,
  updateStatus,
  assignRider
} from '../apollo/server'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import OrderComponent from '../components/Order/Order'
import { transformToNewline } from '../utils/stringManipulations'
import { handleErrors } from '../utils/handleErrors'
import moment from 'moment'


const ordersInMemory = new Set()

const Orders = props => {
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [notification, setNotifications] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const columns = [
    {
      name: 'Order ID',
      sortable: true,
      selector: 'orderId',
      cell: row => (
        <Subscription
          subscription={gql`
            ${subscriptionOrder}
          `}
          variables={{ id: row._id }}>
          {() => {
            return (
              <div style={{ overflow: 'visible', whiteSpace: 'pre' }}>
                {row.orderId}
              </div>
            )
          }}
        </Subscription>
      )
    },
    {
      name: 'Restaurant Information',
      sortable: true,
      selector: 'orderId',
      cell: row => (
          <div style={{ overflow: 'visible', whiteSpace: 'pre' }}>
            {row.restaurant.name}
            <br />
            {row.restaurant.phone}
          </div>
      )
    },
    {
      name: 'Customer Information',
      sortable: true,
      selector: 'orderId',
      cell: row => (
          <div style={{ overflow: 'visible', whiteSpace: 'pre' }}>
            {row.user.name}
            <br />
            {row.user.email}
            <br />
            {row.user.phone}
          </div>
      )
    },
    {
      name: 'Delivery Address',
      sortable: true,
      selector: 'orderId',
      cell: row => (
          <div style={{overflow: 'visible', whiteSpace: 'pre'}}>
            {row.isPickedUp && (
                <>PICK UP ORDER</>
            )}
            {!row.isPickedUp && (
                <>{transformToNewline(row.deliveryAddress.deliveryAddress, 3)}</>
            )}
          </div>
      )
    },
    {
      name: 'Status',
      selector: 'orderStatus',
      cell: row => (
        <div style={{ overflow: 'visible' }}>
          {row.orderStatus}
          {!['CANCELLED', 'DELIVERED'].includes(row.orderStatus) && (
            <div style={{ overflow: 'visible' }}>
              <UncontrolledDropdown size="sm" style={{ overflow: 'visible' }}>
                <DropdownToggle caret>Select</DropdownToggle>
                <Mutation
                  mutation={gql`
                    ${updateStatus}
                  `}>
                  {updateStatus => (
                    <DropdownMenu>
                      {row.orderStatus === 'PENDING' && (
                        <DropdownItem
                          onClick={() => {
                            updateStatus({
                              variables: {
                                id: row._id,
                                orderStatus: 'ACCEPTED'
                              }
                            })
                          }}>
                          Accept
                        </DropdownItem>
                      )}
                      {['PENDING', 'ACCEPTED'].includes(row.orderStatus) && (
                        <DropdownItem
                          onClick={() => {
                            updateStatus({
                              variables: {
                                id: row._id,
                                orderStatus: 'ASSIGNED'
                              }
                            })
                          }}>
                          Assigned
                        </DropdownItem>
                      )}
                      {['PENDING', 'ACCEPTED', 'ASSIGNED', 'PICKED'].includes(
                        row.orderStatus
                      ) && (
                        <DropdownItem
                          onClick={() => {
                            updateStatus({
                              variables: {
                                id: row._id,
                                orderStatus: 'CANCELLED'
                              }
                            })
                          }}>
                          Cancel
                        </DropdownItem>
                      )}
                      {['PENDING', 'ACCEPTED', 'ASSIGNED', 'PICKED'].includes(
                        row.orderStatus
                      ) && (
                        <DropdownItem
                          onClick={() => {
                            updateStatus({
                              variables: {
                                id: row._id,
                                orderStatus: 'DELIVERED'
                              }
                            })
                          }}>
                          Delivered
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  )}
                </Mutation>
              </UncontrolledDropdown>
            </div>
          )}
        </div>
      )
    },
    {
      name: 'Driver',
      selector: 'rider',
      cell: row => (
        <div style={{ overflow: 'visible' }}>
          {row.rider ? row.rider.name : ''}
          {(['ACCEPTED', 'ASSIGNED'].includes(row.orderStatus) && !row.isPickedUp) && (
            <div style={{ overflow: 'visible' }}>
              <UncontrolledDropdown size="sm" style={{ overflow: 'visible' }}>
                <DropdownToggle caret>Select</DropdownToggle>
                <Mutation
                  mutation={gql`
                    ${assignRider}
                  `}>
                  {assignRider => (
                    <DropdownMenu>
                      <Query
                        query={gql`
                          ${getRidersByZone}
                        `}
                        variables={{ id: row.restaurant._id }}>
                        {({ loading, error, data }) => {
                          if (loading) return 'Loading...'
                          if (error) return 'Error'
                          return data.ridersByZone.map(rider => (
                            <DropdownItem
                              key={rider._id}
                              onClick={() => {
                                assignRider({
                                  variables: { id: row._id, riderId: rider._id }
                                })
                              }}>
                              {rider.name}
                            </DropdownItem>
                          ))
                        }}
                      </Query>
                    </DropdownMenu>
                  )}
                </Mutation>
              </UncontrolledDropdown>
            </div>
          )}
        </div>
      )
    },
    {
      name: 'Order time (Restaurant TZ)',
      cell: row => (
        <>{(moment.utc(row.createdAt)).format('MMMM Do, h:mm a')}</>
      )
    },
    {
      name: 'Map',
      cell: row => (
        <Button onClick={() => toggleMapModal(row)}>See on Map</Button>
      )
    }
  ]

  const conditionalRowStyles = [
    {
      when: row => ['DELIVERED', 'CANCELLED'].includes(row.orderStatus),
      style: {
        backgroundColor: '#FDEFDD'
      }
    }
  ]

  const toggleDetailModal = order => {
    setSelectedOrder(order)
    setDetailsModalVisible(!detailsModalVisible)
  }

  const toggleMapModal = order => {
    setSelectedOrder(order)
    setMapModalVisible(!mapModalVisible)
  }

  const { t } = props

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            {!notification && (
            <Button
                style={{marginBottom: 30}}
                color="primary" size={"lg"}
                onClick={() => {setNotifications(true)}}>
              Enable Sound Notifications<i style={{marginLeft: 5}} className={"ni ni-button-play"}> </i></Button>
                )}
            <Card className="shadow">
              <Query
                query={gql`
                  ${getActiveOrders}
                `}
                pollInterval={30000}>
                {({ loading, error, data, subscribeToMore }) => {
                  if (error) {
                    const errorMsg = handleErrors(error.graphQLErrors)
                    return (
                      <tr>
                        <td>
                          {t('Error')}! {errorMsg}
                        </td>
                      </tr>
                    )
                  }
                  if(data.getActiveOrders) {
                    for (const order of data.getActiveOrders) {
                      if(!ordersInMemory.has(order.orderId)){
                        ordersInMemory.add(order.orderId)
                        const audio = new Audio("https://snap-delivered.nyc3.digitaloceanspaces.com/assets/notificationbell.mp3");
                        if(notification) audio.play()
                      }
                    }
                  }
                  return (
                    <DataTable
                      title={t('Dispatch')}
                      columns={columns}
                      data={data.getActiveOrders}
                      progressPending={loading}
                      pointerOnHover
                      progressComponent={<CustomLoader />}
                      pagination
                      conditionalRowStyles={conditionalRowStyles}
                      onRowClicked={toggleDetailModal}
                    />
                  )
                }}
              </Query>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={detailsModalVisible}
        toggle={() => {
          toggleDetailModal(null)
        }}>
        <OrderComponent order={selectedOrder} />
      </Modal>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={mapModalVisible}
        toggle={() => {
          toggleMapModal(null)
        }}>
        {selectedOrder && (
          <GoogleMap
            mapContainerStyle={{
              height: '500px',
              width: '100%'
            }}
            id="example-map"
            zoom={12}
            center={{
              lat: parseFloat(selectedOrder.restaurant.location.coordinates[1]),
              lng: parseFloat(selectedOrder.restaurant.location.coordinates[0])
            }}>
            <>
              <Marker
                icon={{
                  path:
                    'M16.5,13.5h-3V3h-3V13.5h-3V3h-3V13.5a5.979,5.979,0,0,0,5.625,5.955V33h3.75V19.455A5.979,5.979,0,0,0,19.5,13.5V3h-3ZM24,9V21h3.75V33H31.5V3C27.36,3,24,6.36,24,9Z',
                  fillOpacity: 1,
                  fillColor: '#DD4C37'
                }}
                position={{
                  lat: parseFloat(
                    selectedOrder.restaurant.location.coordinates[1]
                  ),
                  lng: parseFloat(
                    selectedOrder.restaurant.location.coordinates[0]
                  )
                }}
              />
              <Marker
                icon={{
                  path:
                    'M19.714,10.425,6.75,21.1V32.625A1.125,1.125,0,0,0,7.875,33.75l7.879-.02A1.125,1.125,0,0,0,16.874,32.6v-6.73A1.125,1.125,0,0,1,18,24.75h4.5a1.125,1.125,0,0,1,1.125,1.125V32.6a1.125,1.125,0,0,0,1.125,1.129l7.876.022a1.125,1.125,0,0,0,1.125-1.125V21.094L20.789,10.425A.857.857,0,0,0,19.714,10.425Zm20.477,7.257-5.878-4.845V3.1a.844.844,0,0,0-.844-.844H29.531a.844.844,0,0,0-.844.844V8.2l-6.3-5.179a3.375,3.375,0,0,0-4.289,0L.305,17.681A.844.844,0,0,0,.193,18.87l1.793,2.18a.844.844,0,0,0,1.189.115L19.714,7.542a.857.857,0,0,1,1.076,0l16.54,13.622a.844.844,0,0,0,1.188-.112l1.793-2.18a.844.844,0,0,0-.12-1.19Z',
                  fillOpacity: 1,
                  fillColor: '#DD4C37'
                }}
                position={{
                  lat: parseFloat(
                    selectedOrder.deliveryAddress.location.coordinates[1]
                  ),
                  lng: parseFloat(
                    selectedOrder.deliveryAddress.location.coordinates[0]
                  )
                }}
              />
              {selectedOrder.rider && (
                <Marker
                  icon={{
                    path:
                      'M38.25,13.5H37.125L29.476,3.939A4.5,4.5,0,0,0,25.962,2.25H10.922A4.5,4.5,0,0,0,6.744,5.079l-3.369,8.58A4.489,4.489,0,0,0,0,18v7.875A1.125,1.125,0,0,0,1.125,27H4.5A6.75,6.75,0,0,0,18,27h9a6.75,6.75,0,0,0,13.5,0h3.375A1.125,1.125,0,0,0,45,25.875V20.25A6.75,6.75,0,0,0,38.25,13.5Zm-27,16.875A3.375,3.375,0,1,1,14.625,27,3.379,3.379,0,0,1,11.25,30.375ZM16.313,13.5H8.222l2.7-6.75h5.391Zm3.375,0V6.75h6.275l5.4,6.75ZM33.75,30.375A3.375,3.375,0,1,1,37.125,27,3.379,3.379,0,0,1,33.75,30.375Z',
                    fillOpacity: 1,
                    fillColor: '#DD4C37'
                  }}
                  position={{
                    lat: parseFloat(
                      selectedOrder.rider.location.coordinates[1]
                    ),
                    lng: parseFloat(selectedOrder.rider.location.coordinates[0])
                  }}
                />
              )}
            </>
          </GoogleMap>
        )}
      </Modal>
    </>
  )
}

export default compose(withApollo, withTranslation())(Orders)
