/* eslint-disable react/display-name */
import React from 'react'
// reactstrap components
import { Row, Col } from 'reactstrap'
// core components
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'

const DayComponent = ({
  day,
  values = [],
  onChangeTime,
  resetTitle = '',
  startTitle = '',
  canAddMultiple = true
}) => {
  return (
    <Col>
      <Row className="mt-2" style={{ background: 'aliceblue' }}>
        <Col lg="6">
          <div className="row justify-content">
            <div className="col mt-2 mb-2">
              <label className="form-control-label">{day}</label>
            </div>
            <div className="col mt-2 mb-2">
              {values.length > 0 && Boolean(resetTitle) && (
                <button
                  onClick={e => {
                    e.preventDefault()
                    onChangeTime(day, [])
                  }}
                  className="btn btn-outline-danger btn-sm">
                  {resetTitle}
                </button>
              )}
              {values.length === 0 && Boolean(startTitle) && (
                <button
                  onClick={e => {
                    e.preventDefault()
                    onChangeTime(day, [['00:00', '23:59']])
                  }}
                  className="btn btn-outline-primary btn-sm">
                  {startTitle}
                </button>
              )}
            </div>
          </div>
        </Col>
        <Col lg="6">
          {values.map((value, index) => (
            <Row key={day + index} className="mt-2 mb-2">
              <TimeRangePicker
                required
                rangeDivider="to"
                disableClock
                format="hh:mm a"
                clearIcon={null}
                value={value}
                onChange={valueInner => {
                  values[index] = valueInner
                  onChangeTime(day, [...values])
                }}
              />
              {canAddMultiple && (
                <>
                  <div className="col col-lg-6">
                    {index === values.length - 1 && (
                      <button
                        onClick={e => {
                          e.preventDefault()
                          onChangeTime([...value, ['00:00', '23:59']])
                        }}
                        className="btn btn-outline-primary btn-sm">
                        +
                      </button>
                    )}
                    {values.length > 1 && (
                      <button
                        onClick={e => {
                          e.preventDefault()
                          values.splice(index, 1)
                          onChangeTime([...values])
                        }}
                        className="btn btn-outline-danger btn-sm">
                        -
                      </button>
                    )}
                  </div>
                </>
              )}
            </Row>
          ))}
          {values.length === 0 && (
            <Row className="mt-2 mb-2">
              <span>Closed All day</span>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  )
}

export default DayComponent
